import React from 'react'
import SettingsHeaderBack from 'app/components/settings/SettingsHeaderBack'
import Box from 'components/Box'
import Button from 'components/Button'
import Container from 'components/Container'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import { ReactComponent as StarSvg } from './images/star.svg'

export default function SettingsSubscriptionChangeSuccess() {
  useEventPageVisited(events.SETTINGS_CHANGE_SUBSCRIPTION_SUCCESS_PAGE)
  const globalContext = useGlobalContext()

  return (
    <View className="SettingsSubscriptionChangeSuccess">
      <SettingsHeaderBack />
      <Container flush size="medium">
        <Box align="center" color="white">
          <Row>
            <Row size="small">
              <StarSvg aria-hidden />
            </Row>
            <Row size="small">
              <Text element="h1" size="xxlarge" weight="medium">
                Thank you, {globalContext.user.firstName}! Let’s get you moving.
              </Text>
            </Row>
            <Text element="p">
              Your Bold membership is now active. A confirmation email has been sent to{' '}
              {globalContext.user.email}. We are excited to welcome you to this
              amazing&nbsp;community.
            </Text>
            <Text element="p">Let’s be Bold!</Text>
          </Row>
          <Button to={paths.getProgramPath(globalContext.user)} color="purple">
            View your program
          </Button>
        </Box>
      </Container>
    </View>
  )
}
