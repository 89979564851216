import _ from 'lodash'
import React from 'react'
import adminApiRequest from 'admin/libs/admin-api-request'
import Container from 'components/Container'
import Heading from 'components/Heading'
import Link from 'components/Link'
import Row from 'components/Row'
import View from 'components/View'

export default function Lessons() {
  const [lessons, setLessons] = React.useState([])

  async function getLessons() {
    const getLessonsResponse = await adminApiRequest.getLessons()

    setLessons(getLessonsResponse.data.lessons)
  }

  React.useEffect(() => {
    getLessons()
  }, [])

  if (_.isEmpty(lessons)) return null

  return (
    <div className="AdminLessons">
      <View>
        <Container flush>
          <Row>
            <Heading flush level={1}>
              Lessons
            </Heading>
          </Row>
          <table>
            <tbody>
              {lessons.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Link to={item.zoomJoinUrl} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Container>
      </View>
    </div>
  )
}
