import classNames from 'classnames'
import React from 'react'
import CheckinSingleForm from 'components/CheckinSingleForm'
import Container from 'components/Container'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import ModalConfirm from 'components/ModalConfirm'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as checkinApiRequest from 'libs/checkin-api-request'
import CheckinTracker from 'libs/checkins/checkin-tracker'

type Props = {
  programFollowUpCheckin: any
  onCheckinSuccess: () => void
} & Component

export default function ProgramFollowUpModal({
  className,
  onCheckinSuccess,
  programFollowUpCheckin,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker | null>(null)
  const [isCheckinSubmitted, setIsCheckinSubmitted] = React.useState(false)
  const [modalConfirmButtonText, setModalConfirmButtonText] = React.useState('Close')
  const [isRequired, setIsRequired] = React.useState(true)

  React.useEffect(() => {
    if (programFollowUpCheckin) {
      const code = programFollowUpCheckin.code
      if (code === 'fall_program_3_months' || code === 'fall_program_12_sessions')
        setModalConfirmButtonText('Next')
      // slightly hacky but basically - any fitness program checkin should be optional
      if (
        code?.includes('default_program_follow_up') ||
        code?.includes('pain_only_program_follow_up')
      )
        setIsRequired(false)

      globalContext.analytics?.trackEvent('Program Follow Up Checkin shown', {
        checkinId: programFollowUpCheckin.id,
        checkinCode: code,
      })

      setCheckinTracker(
        new CheckinTracker(globalContext.user?.id, programFollowUpCheckin, globalContext.analytics)
      )
    }
  }, [globalContext.user?.id, programFollowUpCheckin, globalContext.analytics])

  function submitCheckin() {
    globalContext.analytics?.trackEvent('Program Follow Up Checkin submitted', {
      checkinId: checkinTracker?.checkinId,
      checkinCode: checkinTracker?.checkinCode,
    })
    setIsCheckinSubmitted(true)
  }

  function complete() {
    globalContext.update({ programFollowUpCheckinSubmitted: true })
    setCheckinTracker(null)
    onCheckinSuccess()
  }

  function handleManualClose() {
    if (isRequired || !checkinTracker) return
    //mark as complete so it doesn't pop up again. didn't await this for responsiveness
    checkinApiRequest.markUserCheckinAsDismissed(checkinTracker.checkinId)
    globalContext.analytics?.trackEvent('Program Follow Up Checkin manually closed', {
      checkinId: checkinTracker.checkinId,
      checkinCode: checkinTracker.checkinCode,
    })
    complete()
  }

  if (isCheckinSubmitted) {
    return (
      <ModalConfirm
        {...props}
        title="Thank you for providing an update."
        confirmButtonProps={{
          color: 'purple',
        }}
        confirmText={modalConfirmButtonText}
        hideCancel
        size="small"
        onConfirm={complete}
        onRequestClose={complete}
      />
    )
  }

  return (
    <Modal
      {...props}
      className={classNames('ProgramFollowUpModal', className)}
      isOpen={!isCheckinSubmitted}
      hideClose={isRequired}
      onRequestClose={handleManualClose}>
      <ModalBox>
        <Container flush size="small">
          <CheckinSingleForm checkinTracker={checkinTracker} onSuccess={submitCheckin} />
        </Container>
      </ModalBox>
    </Modal>
  )
}
