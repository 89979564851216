import _ from 'lodash'
import React from 'react'
import Select from 'react-select'
import * as adminApiRequest from 'admin/libs/admin-api-request'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Field from 'components/Field'
import Label from 'components/Label'
import Text from 'components/Text'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import notifications from 'libs/notifications'
import LowerBodyMovementDisplay from './LowerBodyMovementDisplay'

type Props = {
  userId: string
}

export default function ProgramChange({ userId }: Props) {
  const globalContext = useGlobalContext()
  const [disableButton, setDisableButton] = React.useState(false)
  const [userProfile, setUserProfile] = React.useState()
  const [allExercisePrograms, setAllExercisePrograms] = React.useState()
  const [bestMatchPrograms, setBestMatchPrograms] = React.useState()
  const [previousPrograms, setPreviousPrograms] = React.useState()
  const [currentProgram, setCurrentProgram] = React.useState()
  const [selectedProgram, setSelectedProgram] = React.useState()

  React.useEffect(() => {
    async function getExercisePrograms() {
      const response = await adminApiRequest.getAllExercisePrograms(userId)
      setBestMatchPrograms(response?.data?.bestMatchPrograms)
      setAllExercisePrograms(response?.data?.allExercisePrograms)
      setUserProfile(response?.data?.userProfile)
    }
    getExercisePrograms()
  }, [userId])

  const getProgram = React.useCallback(async () => {
    const response = await adminApiRequest.getUserExerciseProgram(userId)
    setCurrentProgram(response?.data?.activeProgram?.exerciseProgram)
    setPreviousPrograms(response?.data?.inactivePrograms)
    setSelectedProgram(response?.data?.activeProgram?.exerciseProgram?.id)
  }, [userId])

  React.useEffect(() => {
    getProgram()
  }, [getProgram])

  function handleSelect(value) {
    const selectedExerciseProgram = allExercisePrograms.find((program) => program.id === value)
    setSelectedProgram(selectedExerciseProgram)
  }

  async function handleChange() {
    if (!selectedProgram) {
      notifications.notifyError('No program selected')
      return
    }

    setDisableButton(true)
    const result = await adminApiRequest.updateUserProgram({
      userId,
      exerciseProgramId: selectedProgram?.id,
    })
    setDisableButton(false)

    if (result.statusCode !== statusCodes.SUCCESS) {
      notifications.notifyError('Update did not work')
    } else {
      notifications.notifySuccess('Successfully updated!')
      globalContext.analytics?.trackEvent('Admin changed user program', {
        admin: globalContext.user.email,
        affectedUserId: userId,
        previousProgram: currentProgram?.title,
        newProgram: selectedProgram?.title,
      })
      getProgram()
    }
  }

  async function handleSelectFromMovementProfile(program) {
    setDisableButton(true)
    const result = await adminApiRequest.updateUserProgram({
      userId,
      exerciseProgramId: program?.id,
    })
    setDisableButton(false)

    if (result.statusCode !== statusCodes.SUCCESS) {
      notifications.notifyError('Update did not work')
    } else {
      notifications.notifySuccess('Successfully updated!')
      globalContext.analytics?.trackEvent('Admin changed user program', {
        admin: globalContext.user.email,
        affectedUserId: userId,
        previousProgram: currentProgram?.title,
        newProgram: program?.title,
      })
      getProgram()
    }
  }

  if (!userProfile) return null

  let levels = allExercisePrograms?.map((program) => ({
    label: program.title,
    value: program.id,
  }))

  levels = _.sortBy(levels, ['label'])

  return (
    <div className="AdminUsersProgramChange">
      {!!currentProgram && (
        <Field>
          <Label>Current</Label>
          <Text weight="bold">{currentProgram.title}</Text>
        </Field>
      )}
      <Label>Previous</Label>
      {previousPrograms?.map((program) => (
        <>
          {program.exerciseProgram.title}
          <br />
        </>
      ))}
      <br />
      <br />
      <Field>
        <Label>All program data shown is for the first block only</Label>
        <LowerBodyMovementDisplay
          userProfile={userProfile}
          currentProgram={currentProgram}
          bestMatchPrograms={bestMatchPrograms}
          onSelect={handleSelectFromMovementProfile}
        />
        <br />
        <Text>OR Just select ANY program</Text>
        <Select
          options={levels}
          placeholder="Select program…"
          selected={levels && levels.filter((level) => level.value === selectedProgram?.id)}
          styles={{
            control: (controlStyles) => ({
              ...controlStyles,
              //height: '50px',
            }),
          }}
          onChange={(value) => handleSelect(value !== null ? value.value : null)}
        />
      </Field>
      <Buttons align="right">
        <Button onClick={handleChange} disabled={disableButton}>
          Update
        </Button>
      </Buttons>
    </div>
  )
}
