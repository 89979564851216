import { navigate } from 'gatsby'
import React from 'react'
import FormFieldsRequired from 'components/FormFieldsRequired'
import * as events from 'constants/events'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as cookie from 'libs/cookie'
import notifications from 'libs/notifications'
import * as subscriptionApiRequest from 'libs/subscription-api-request'
import subscriptions from 'libs/subscriptions'
import user from 'libs/user'
import userApiRequest from 'libs/user-api-request'
import OnboardingLayout from 'onboarding/components/OnboardingLayout'
import OnboardingView from 'onboarding/components/OnboardingView'
import OnboardingProfileForm from './components/OnboardingProfileForm'

export default function OnboardingProfile() {
  useEventPageVisited(events.ONBOARDING_PROFILE_PAGE)
  const globalContext = useGlobalContext()
  const [disabled, setDisabled] = React.useState(false)

  async function submit(updatedUser: any) {
    if (!globalContext.user) return

    // Handle Stripe stuff
    if (
      !user.isEnterpriseUser(updatedUser) &&
      !user.isOnboardingPaymentRequired(globalContext.user)
    ) {
      // 1 - Ensure user is a stripe customer
      let stripeCustomerId = globalContext.user.stripeCustomerId
      if (!stripeCustomerId) {
        const stripeResponse = await userApiRequest.createStripeCustomerForUser({
          email: globalContext.user.email,
          stripePaymentSourceToken: '',
        })

        if (stripeResponse.statusCode === statusCodes.INTERNAL_SERVER_ERROR) {
          const message = stripeResponse.message || stripeResponse.error.message
          console.error(message)
          notifications.notifyError()

          await userApiRequest.updateUser({
            isOnboardingCompleted: false, // ensures they stay on profile page on reload, otherwise would go to dashboard
            stripePaymentSourceToken: '',
          })

          await globalContext.analytics?.trackEvent(events.PROFILE_CREATION_FAILED, {
            error: message,
          })
          return
        }

        stripeCustomerId = stripeResponse.data.stripeCustomerId
      }

      // 2 - Subscribe user to free trial
      await globalContext.analytics?.trackEvent(events.SELECT_BASIC_SUBSCRIPTION)

      const plans = await subscriptionApiRequest.getPlans()
      const freePlan = plans.data.plans.find((item) => subscriptions.isFreePlan(item))

      // Attempt to find a promo code from the user's cookies
      // If found, apply it to the subscription request
      const promoCodeValue = cookie.getCookie(storage.PROMO_CODE_KEY)
      let coupon

      if (promoCodeValue && promoCodeValue.length) {
        coupon = subscriptions.findPromoCode(promoCodeValue, plans.data.plans)
      }

      const subscribeUserToPlanResponse = await subscriptionApiRequest.subscribeUserToPlan({
        coupon,
        freeTrialDays: subscriptions.TRIAL_DAYS,
        stripePlanId: freePlan?.id,
      })

      await globalContext.update({
        user: {
          ...globalContext.user,
          ...subscribeUserToPlanResponse.data,
        },
      })
    }

    await globalContext.analytics?.trackEvent(
      events.PROFILE_CREATION_COMPLETE,
      { coupon: cookie.getCookie(storage.PROMO_CODE_KEY) },
      {
        trialLength: !user.isEnterpriseUser(updatedUser) ? subscriptions.TRIAL_DAYS : null,
        profileCompletedDate: new Date(),
      }
    )

    await globalContext.updateUser()

    navigate(
      user.isOnboardingPaymentRequired(globalContext.user)
        ? paths.ONBOARDING_PAYMENT
        : paths.ONBOARDING_PROGRAM_BUILD,
      { replace: true }
    )
  }

  async function handleSuccess(updatedUser: any) {
    setDisabled(true)
    await submit(updatedUser)
  }

  return (
    <OnboardingLayout title="Profile">
      <OnboardingView
        small="Finish profile"
        title={`Complete sign up to get your personalized program ${
          user.isEnterpriseUser(globalContext.user) ? 'at no cost' : ''
        }`}>
        <FormFieldsRequired />
        <OnboardingProfileForm disabled={disabled} onSuccess={handleSuccess} />
      </OnboardingView>
    </OnboardingLayout>
  )
}
