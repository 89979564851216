import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import LessonFeedback from 'app/components/LessonFeedback'
import Box from 'components/Box'
import Container from 'components/Container'
import Text from 'components/Text'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as lessonLib from 'libs/lesson'
import * as userApiRequest from 'libs/user-api-request'

export default function LiveLessonFeedback() {
  const globalContext = useGlobalContext()
  const [liveLesson, setLiveLesson] = React.useState()

  React.useEffect(() => {
    async function getData() {
      const recentLessonsResults = await userApiRequest.getRecentLessons()
      if (recentLessonsResults.statusCode !== 200 || !recentLessonsResults?.data) return
      const mostRecentLiveLesson = _.find(
        recentLessonsResults.data,
        (lesson) => !!lesson?.lessonStreamId
      )
      if (!mostRecentLiveLesson) return
      setLiveLesson(mostRecentLiveLesson)

      globalContext.analytics?.trackEvent(events.OPEN_LIVE_LESSON_FEEDBACK, {
        classType1: lessonLib.MEDIUM_LIVE,
        lessonId: mostRecentLiveLesson.id,
        lessonStreamId: mostRecentLiveLesson.lessonStreamId,
        lessonName: mostRecentLiveLesson.videoTitle,
        trainerName: mostRecentLiveLesson.instructorName,
        zoomScheduledStartTs: mostRecentLiveLesson.scheduledStartTs,
      })
    }
    getData()
  }, [globalContext.analytics])

  return (
    <View className="LiveLessonFeedback">
      <Container flush size="small">
        <Box>
          <Text align="center" element="div">
            <LessonFeedback
              liveLessonId={liveLesson?.id}
              liveLessonStreamId={liveLesson?.lessonStreamId}
              onSuccess={() => {
                navigate(paths.HOME_AUTHED)
              }}
            />
          </Text>
        </Box>
      </Container>
    </View>
  )
}
