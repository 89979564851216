import classNames from 'classnames'
import React from 'react'
import CheckinForm from 'components/CheckinForm'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import ModalConfirm from 'components/ModalConfirm'
import Text from 'components/Text'
import * as events from 'constants/events'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as checkinApiRequest from 'libs/checkin-api-request'
import notifications from 'libs/notifications'
import CheckinTracker from 'libs/checkins/checkin-tracker'

type Props = {
  // TODO: fix lint issue
  isOpen: boolean
  onSuccess: () => void
} & Component

export default function EnterpriseOctopusBalanceCheckinModal({
  className,
  onSuccess,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker | null>(null)
  const [isCheckinSubmitted, setIsCheckinSubmitted] = React.useState(false)

  React.useEffect(() => {
    globalContext.analytics?.trackEvent(events.ALTERNATE_BALANCE_ASSESSMENT_VIEW)
  }, [globalContext.analytics])

  React.useEffect(() => {
    async function getCheckin() {
      const getBalanceCheckin = await checkinApiRequest
        .getUserCheckin({
          type: 'assessment',
          code: 'balance',
          activeOnly: true,
          createIfNotExists: true,
        })
        .catch((error: any) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: 'enterprise octopus checkin',
            type: 'assessment',
          })
          notifications.notifyError()
        })

      const balanceCheckin = getBalanceCheckin.data

      if (!balanceCheckin) return

      setCheckinTracker(
        new CheckinTracker(globalContext.user?.id, balanceCheckin, globalContext.analytics)
      )
    }
    getCheckin()
  }, [globalContext.user?.id, globalContext.analytics])

  function submitCheckin(answers) {
    globalContext.analytics?.trackEvent(events.ALTERNATE_BALANCE_ASSESSMENT_COMPLETE, {
      answers: JSON.stringify(answers),
    })

    setIsCheckinSubmitted(true)
  }

  function complete() {
    onSuccess()
  }

  if (isCheckinSubmitted) {
    return (
      <ModalConfirm
        {...props}
        title="Thank you!"
        confirmText="Let‘s get moving!"
        confirmButtonProps={{
          color: 'purple',
        }}
        hideCancel
        size="small"
        onConfirm={complete}
        onRequestClose={complete}>
        <Copy align="left">
          <Text element="p">
            Feeling unsteady is a common experience which is why we like to check in on your balance
            from time to time. Things like balance training, building our strength and endurance,
            and tuning our brain to body connection, can go a long way to reducing our risk of
            falling – at every age.
          </Text>
          <Text element="p">
            Bold’s Balance and Stability program combines all of these elements to help you reclaim
            your stability and conquer your fears.
          </Text>
        </Copy>
      </ModalConfirm>
    )
  }

  return (
    <Modal {...props} onRequestClose={complete}>
      <ModalBox
        title="Tell us more about your balance"
        className={classNames('EnterpriseOctopusBalanceCheckinModal', className)}>
        <Container flush size="small">
          <CheckinForm
            buttonProps={{
              children: 'Next',
              color: 'purple',
              full: true,
            }}
            checkinTracker={checkinTracker}
            onSuccess={submitCheckin}
          />
        </Container>
      </ModalBox>
    </Modal>
  )
}
