import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import * as adminApiRequest from 'admin/libs/admin-api-request'
import Button from 'components/Button'
import Field2 from 'components/Field2'
import Heading from 'components/Heading'
import Row from 'components/Row'
import Text from 'components/Text'
import statusCodes from 'constants/status-codes'
import validations from 'libs/validations'

type Props = {
  onError: () => void
  onSuccess: () => void
}

export default function AdminEnterpriseUserMigrate({ onSuccess, onError }: Props) {
  function handleSubmit(values, formikActions) {
    const { email } = values
    adminApiRequest.migrateEnterpriseUser(email).then((response) => {
      if (response.statusCode === statusCodes.SUCCESS && onSuccess) {
        onSuccess(response)
        formikActions.resetForm()
      } else if (onError) {
        onError(response)
      }

      formikActions.setSubmitting(false)
    })
  }

  return (
    <div>
      <Heading level={2}>Migrate an enterprise user by email</Heading>
      <Text element="p">Migrates an enterprise user to a core user with trial membership.</Text>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object({
          email: validations.REQUIRED,
        })}
        onSubmit={handleSubmit}>
        {(formikProps) => (
          <Form>
            <Field2 label="Email" name="email" />
            <Row size="xsmall">
              <Button
                id="migrateEnterpriseUser"
                color="red"
                disabled={formikProps.isSubmitting}
                onClick={formikProps.submitForm}>
                Migrate user
              </Button>
            </Row>
            <Text color="red" element="p" flush>
              Warning this cannot be undone!
            </Text>
          </Form>
        )}
      </Formik>
    </div>
  )
}
