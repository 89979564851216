import { Form, Formik } from 'formik'
import _ from 'lodash'
import pluralize from 'pluralize'
import React from 'react'
import * as Yup from 'yup'
import Box from 'components/Box'
import Button from 'components/Button'
import Field from 'components/Field'
import Field2 from 'components/Field2'
import TextWithIcon from 'components/TextWithIcon'
import { useGlobalContext } from 'contexts/GlobalContext'
import { useUserGoal } from 'libs/user-query'
import validations from 'libs/validations'
import { ReactComponent as CalendarSvg } from './images/calendar.svg'

type Props = {
  onSubmit: () => void
}

export default function GoalForm({ onSubmit }: Props) {
  const globalContext = useGlobalContext()
  const { data: userGoalData } = useUserGoal()

  function handleSubmit(values) {
    globalContext.analytics?.trackEvent('Goal and reminders: clicked next')
    onSubmit(values.days)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        days: userGoalData?.data?.goalExerciseFrequency?.toString() ?? '3',
      }}
      validationSchema={Yup.object({
        days: validations.REQUIRED,
      })}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <Field>
            <Box color="grey">
              <Field2
                label={
                  <TextWithIcon icon={<CalendarSvg />}>
                    How many days a week do you want to exercise?
                  </TextWithIcon>
                }
                entity="days"
                flush
                name="days"
                options={_.times(7, (index) => ({
                  label: `${pluralize('day', index + 1, true)} a week`,
                  value: Number(index + 1).toString(),
                }))}
                type="select"
              />
            </Box>
          </Field>
          <Button disabled={formikProps.isSubmitting} full onClick={formikProps.submitForm}>
            Next
          </Button>
        </Form>
      )}
    </Formik>
  )
}
