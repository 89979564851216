import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import * as adminApiRequest from 'admin/libs/admin-api-request'
import Button from 'components/Button'
import Container from 'components/Container'
import Field2 from 'components/Field2'
import Heading from 'components/Heading'
import View from 'components/View'
import statusCodes from 'constants/status-codes'
import notifications from 'libs/notifications'
import validations from 'libs/validations'

export default function AdminCollectionCreate() {
  const [collectionId, setCollectionId] = React.useState('')

  const ALL = 'all'
  async function handleSubmitCollection(values, formikActions) {
    values.lessonIds = values.lessonIds.split(',').map((lessonId) => lessonId.trim('\n'))
    values.tags = ALL ? ['default'] : [values.tags]
    const response = await adminApiRequest.createCollection(values)

    if (response.statusCode === statusCodes.SUCCESS) {
      notifications.notifySuccess(response.data.message)
      setCollectionId(response.data.collection.id)
    } else {
      notifications.notifyError(response.message)
    }

    formikActions.setSubmitting(false)
  }

  async function handleSubmitChallenge(values, formikActions) {
    if (collectionId) {
      values.collectionId = collectionId
    }

    const response = await adminApiRequest.createChallenge(values)

    if (response.statusCode === statusCodes.SUCCESS) {
      notifications.notifySuccess(response.data.message)
    } else {
      notifications.notifyError(response.message)
    }

    formikActions.setSubmitting(false)
  }

  return (
    <div className="AdminCollectionCreate">
      <View>
        <Container align="left" size="small">
          <Heading level={2}>Create Collection</Heading>
          <Formik
            initialValues={{
              title: '',
              description: '',
              tags: '',
              lessonIds: '',
            }}
            validationSchema={Yup.object({
              title: validations.REQUIRED,
              description: validations.REQUIRED,
              tags: validations.REQUIRED,
              lessonIds: validations.REQUIRED,
            })}
            onSubmit={handleSubmitCollection}>
            {(formikProps) => (
              <Form>
                <Field2 label="Title" name="title" />
                <Field2 label="Description" name="description" />
                <Field2
                  label="Which members should see this collection?"
                  entity="tags"
                  name="tags"
                  type="select"
                  options={[
                    {
                      label: 'All members',
                      value: ALL,
                    },
                    {
                      label: 'Prefers Standing',
                      value: 'standing',
                    },
                    {
                      label: 'Prefers Standing/Floor',
                      value: 'standing/floor',
                    },
                    {
                      label: 'Prefers Seated',
                      value: 'seated',
                    },
                    {
                      label: 'Indicated Back Pain',
                      value: 'back pain',
                    },
                    {
                      label: 'Indicated Arthritis',
                      value: 'arthritis',
                    },
                    {
                      label: 'Indicated copd',
                      value: 'copd',
                    },
                    {
                      label: 'Indicated diabetes',
                      value: 'diabetes',
                    },
                    {
                      label: 'Indicated hypertension',
                      value: 'hypertension',
                    },
                  ]}
                />
                <Field2
                  placeholder="e.g. 012fdce4-ef26-4538-ad38-fbd2df1c0145, c127d7f5-9988-4c22-9a9f-4f86cddf8eba..."
                  label="Lesson IDs (comma-separated in desired order) "
                  type="textarea"
                  name="lessonIds"
                />
                <Button
                  id="createCollection"
                  disabled={formikProps.isSubmitting}
                  onClick={formikProps.submitForm}>
                  Create Collection
                </Button>
              </Form>
            )}
          </Formik>

          <div style={{ marginTop: '24px' }}>
            <Heading level={2}>Create Challenge</Heading>
            <Formik
              initialValues={{
                name: '',
                description: '',
                disclaimer: '',
                unit: '',
                threshold: '',
                newProgramId: null,
                collectionId: collectionId || null,
                activatedAt: '',
                deactivatedAt: '',
              }}
              validationSchema={Yup.object({
                name: validations.REQUIRED,
                description: validations.REQUIRED,
                disclaimer: validations.REQUIRED,
                unit: Yup.string().oneOf(['lesson', 'blog', 'minutes']).required(),
                threshold: validations.NUMBER_POSITIVE_REQUIRED,
                newProgramId: Yup.string().nullable(),
                collectionId: Yup.string().nullable(),
                activatedAt: validations.REQUIRED,
                deactivatedAt: validations.REQUIRED,
              })}
              onSubmit={handleSubmitChallenge}>
              {(formikProps) => (
                <Form>
                  <Field2 label="Name" name="name" />
                  <Field2 label="Description" name="description" />
                  <Field2 label="Disclaimer" name="disclaimer" />
                  <Field2
                    label="Unit of challenge progress"
                    name="unit"
                    placeholder="e.g. number of lessons. or minutes watched"
                    type="select"
                    options={[
                      {
                        label: 'Number of lessons watched',
                        value: 'lesson',
                      },
                      {
                        label: 'Number of minutes watched',
                        value: 'minutes',
                      },
                      {
                        label: 'Number of blogs read',
                        value: 'blog',
                      },
                    ]}
                  />
                  <Field2
                    label="Threshold for challenge completion"
                    name="threshold"
                    placeholder="Number of lessons / minutes to complete the challenge"
                  />
                  <Field2
                    label="Date to start challenge on"
                    name="activatedAt"
                    placeholder="2022-08-28 00:00:00+00"
                  />
                  <Field2
                    label="Date to end challenge on"
                    name="deactivatedAt"
                    placeholder="2022-08-28 00:00:00+00"
                  />
                  <Field2
                    label="Collection Id to link member to upon joining challenge (optional)"
                    name="collectionId"
                    value={collectionId}
                  />
                  <Field2
                    label="Program Id to switch member to upon joining challenge (optional)"
                    name="newProgramId"
                  />
                  <div style={{ marginBottom: '48px' }}>
                    <Button
                      id="createChallenge"
                      disabled={formikProps.isSubmitting}
                      onClick={formikProps.submitForm}>
                      Create Challenge
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </View>
    </div>
  )
}
