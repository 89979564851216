import classNames from 'classnames'
import React from 'react'
import CheckinForm from 'components/CheckinForm'
import ModalConfirm from 'components/ModalConfirm'
import emails from 'constants/emails'
import * as events from 'constants/events'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as checkinApiRequest from 'libs/checkin-api-request'
import notifications from 'libs/notifications'
import programs from 'libs/programs'
import user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'
import CheckinTracker from 'libs/checkins/checkin-tracker'
import styles from './ProgramPostLessonFeedbackForm.module.scss'

type Props = {
  lessonId: string
  onSuccess: () => void
} & Component

export default function ProgramPostLessonFeedbackForm({
  className,
  lessonId: propsLessonId,
  onSuccess,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState()
  const [isSubmittingChangeProgram, setIsSubmittingChangeProgram] = React.useState(false)
  const [lessonFeedbackDifficulty, setLessonFeedbackDifficulty] = React.useState('')

  const lessonId = propsLessonId || globalContext.lesson?.id
  const lessonTitle = globalContext.lesson?.videoTitle

  React.useEffect(() => {
    globalContext.analytics?.trackEvent(events.START_FEEDBACK, {
      classId: lessonId,
      isExplore: window.location?.hash === '#explore',
      lessonName: lessonTitle,
    })
  }, [globalContext.analytics, lessonId, lessonTitle])

  React.useEffect(() => {
    async function getCheckin() {
      const afterClassCheckinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'after_class',
          activeOnly: true,
          objectId: lessonId,
          createIfNotExists: true,
        })
        .catch((error: any) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: 'program post lesson feedback form',
            type: 'after_class',
          })
          notifications.notifyError()
        })

      const afterClassCheckin = afterClassCheckinResponse.data

      if (!afterClassCheckin) return

      setCheckinTracker(
        new CheckinTracker(globalContext.user?.id, afterClassCheckin, globalContext.analytics)
      )
    }
    getCheckin()
  }, [globalContext.user?.id, lessonId, globalContext.analytics])

  function handleSuccess(answers) {
    notifications.notifySuccess('Thanks for your feedback!')

    const isExplore = window.location?.hash === '#explore'

    globalContext.analytics?.trackEvent(events.COMPLETE_FEEDBACK, {
      answers: JSON.stringify(answers),
      classId: lessonId,
      isExplore,
      lessonName: globalContext.lesson?.videoTitle,
    })

    if (isExplore || !user.canChangeProgram(globalContext.user)) {
      onSuccess()
      return
    }

    const challengingAnswer = answers.after_class_how_challenging
    const currentProgram = globalContext?.user?.userExerciseProgram?.exerciseProgram

    // only show the option to switch programs if one exists that is easier or harder
    if (challengingAnswer === '1' && currentProgram?.ifTooEasy) {
      setLessonFeedbackDifficulty(programs.CLASS_TOO_EASY)
      return
    }

    if (challengingAnswer === '5' && currentProgram?.ifTooHard) {
      setLessonFeedbackDifficulty(programs.CLASS_TOO_HARD)
      return
    }

    onSuccess()
  }

  async function handleChangeProgramConfirm() {
    // Prevent double-click: https://stackoverflow.com/questions/35315872/reactjs-prevent-multiple-times-button-press
    if (isSubmittingChangeProgram) return

    setIsSubmittingChangeProgram(true)

    const previousProgram = globalContext?.user?.currentExerciseProgram

    const response = await userApiRequest.switchUserExerciseProgram(lessonFeedbackDifficulty)

    if (response.statusCode !== 200) {
      notifications.notifyError(
        `Sorry, we couldn’t change your program right now. Please contact us at ${emails.DEFAULT}.`
      )
    } else {
      notifications.notifySuccess('Program updated!')
      globalContext.updateUser()
    }

    globalContext.analytics?.trackEvent(events.SWITCH_USER_EXERCISE_PROGRAM, {
      newProgram: response?.data?.title,
      previousProgram,
      reason: `previous_program_too_${lessonFeedbackDifficulty}`,
      success: response.statusCode === 200 ? 'true' : 'false',
      switchInitiatedFrom: 'post_lesson_feedback',
    })

    setLessonFeedbackDifficulty('')
    setIsSubmittingChangeProgram(false)

    //TODO change this. Ideally, reloading the page is temp fix.
    if (response.statusCode === 200) {
      window.location.reload()
    } else {
      onSuccess()
    }
  }

  function handleChangeProgramCancel() {
    setLessonFeedbackDifficulty('')
    onSuccess()
  }

  return (
    <>
      <CheckinForm
        {...props}
        align="left"
        buttonProps={{
          children: 'Submit feedback',
          color: 'purple',
          full: true,
        }}
        checkinTracker={checkinTracker}
        className={classNames('ProgramPostLessonFeedbackForm', styles.this, className)}
        onSuccess={handleSuccess}
      />
      <ModalConfirm
        title={`Would you like to try a ${
          lessonFeedbackDifficulty === programs.CLASS_TOO_EASY ? 'more' : 'less'
        } difficult set of lessons?`}
        cancelText="No"
        confirmText="Yes"
        isOpen={!!lessonFeedbackDifficulty}
        size="small"
        onConfirm={handleChangeProgramConfirm}
        onRequestClose={handleChangeProgramCancel}
      />
    </>
  )
}
