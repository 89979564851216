import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Image from 'components/Image'
import type { Component, Lesson } from 'constants/types'
import * as lesson from 'libs/lesson'
import styles from './LessonTrainers.module.scss'

type Props = {
  data: Lesson[]
} & Component

export default function LessonTrainers({ className, data }: Props) {
  if (_.isEmpty(data)) return null

  return (
    <div className={classNames('LessonTrainers', styles.this, className)}>
      {data.slice(0, Math.min(2, _.size(data))).map((item: Lesson) => (
        <Image
          src={lesson.getTrainerImage(item.instructorName)}
          key={item.instructorName}
          className={classNames(styles.photo, 'LessonTrainers--photo')}
        />
      ))}
    </div>
  )
}
