import { navigate } from 'gatsby'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import paths from 'constants/paths'
import storage from 'constants/storage'
import urls from 'constants/urls'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as cookie from 'libs/cookie'
import url from 'libs/url'
import * as userApiRequest from 'libs/user-api-request'
import OnboardingLayout from 'onboarding/components/OnboardingLayout'
import OnboardingView from 'onboarding/components/OnboardingView'
import { ReactComponent as ClockCounterClockwiseSvg } from './images/clock---counter-clockwise.svg'
import { ReactComponent as UserSvg } from './images/user.svg'
import { ReactComponent as WalkSvg } from './images/walk.svg'

const AI_DEFAULT_RESPONSE = 'Your personalized program will be tailored to meet your unique needs.'

export default function OnboardingProgramIntroduction() {
  const globalContext = useGlobalContext()
  const [aiGeneratedCopy, setAiGeneratedCopy] = React.useState<string | undefined>()
  const [baselineCopy, setBaselineCopy] = React.useState<string | undefined>()
  const [durationCopy, setDurationCopy] = React.useState<string | undefined>()
  const [intensityCopy, setIntensityCopy] = React.useState<string | undefined>()

  useEventPageVisited('Onboarding program intro')

  const getDurationCopy = (durationResponse: string) => {
    // TODO use constants
    switch (durationResponse) {
      case '<15 min': {
        return 'less than 15 minutes long'
      }
      case '15-25 min': {
        return '15 to 25 minutes long'
      }
      case '25-35 min': {
        return '25 to 35 minutes long'
      }
      case '35+ min': {
        return 'more than 35 minutes long'
      }
      default: {
        // should never get here based on current OB flows
        return 'available anytime'
      }
    }
  }

  const handleClick = () => {
    const fromApp = cookie.getCookie(storage.FROM_APP)
    if (fromApp) {
      const accessToken = cookie.getCookie(storage.ACCESS_TOKEN_KEY)
      const queryString = url.turnObjectIntoQueryString({
        userId: globalContext.user.id,
        accessToken,
      })
      window.location.assign(`${urls.APP_URI}?${queryString}`)
    }
    navigate(paths.PROGRAM, { replace: true })
  }

  // capitalizes first letter to Low, Medium, or High
  const getIntensityCopy = (intensityResponse: string) =>
    intensityResponse[0].toUpperCase() + intensityResponse.slice(1)

  const getBaselineCopy = (baselineResponse: any) => {
    // TODO use constants
    switch (baselineResponse) {
      case '3-4/week':
      case '5+/week': {
        return 'Designed for active people like you to keep you moving and expand your existing exercise routine.'
      }
      default: {
        return 'Designed for people like you who want to increase their activity level.'
      }
    }
  }

  React.useEffect(() => {
    async function getPersonalizationInformation() {
      const onboardingResponse = await checkinApiRequest.getLatestUserCheckinResponses({})
      if (onboardingResponse.statusCode !== 200 || !onboardingResponse?.data) return

      const durationResponse = onboardingResponse?.data?.preferred_class_duration?.responseValue
      setDurationCopy(getDurationCopy(durationResponse))

      const intensityResponse = onboardingResponse?.data?.cardio_level?.responseValue || 'medium'
      setIntensityCopy(getIntensityCopy(intensityResponse))

      const baselineResponse = onboardingResponse?.data?.current_exercise_habit?.responseValue
      setBaselineCopy(getBaselineCopy(baselineResponse))

      const generatedResponse = await userApiRequest.getLatestUserProfile()
      if (generatedResponse.statusCode !== 200 || !generatedResponse?.data) return

      const generatedCopy =
        generatedResponse?.data?.latestProfile?.generatedProgramDescription || AI_DEFAULT_RESPONSE

      setAiGeneratedCopy(generatedCopy)
    }
    getPersonalizationInformation()
  }, [])

  const sections =
    aiGeneratedCopy === 'Your personalized program will be tailored to meet your unique needs.'
      ? [
          {
            copy: `${intensityCopy} intensity classes that are ${durationCopy}.`,
            icon: UserSvg,
          },
          {
            copy: `${baselineCopy} `,
            icon: ClockCounterClockwiseSvg,
          },
          {
            copy: aiGeneratedCopy,
            icon: WalkSvg,
          },
        ]
      : [
          {
            copy: aiGeneratedCopy,
            icon: WalkSvg,
          },
          {
            copy: `${intensityCopy} intensity classes that are ${durationCopy}.`,
            icon: UserSvg,
          },
          {
            copy: `${baselineCopy} `,
            icon: ClockCounterClockwiseSvg,
          },
        ]

  return (
    <OnboardingLayout
      title="Your program"
      redirectIfOnboardingComplete={false}
      className="OnboardingProgramIntroduction">
      <OnboardingView
        title={
          <>
            Your personalized exercise program is ready,{' '}
            <Text color="purple">{globalContext.user.firstName}</Text>!
          </>
        }>
        <Text element="p">Here’s what you can expect to see:</Text>
        {sections.map((item, index) => (
          <Row key={index} size="smallx">
            <Box color="white" size="small">
              <Row size="xxsmall">
                <MediaObject figure={<item.icon aria-hidden />} center figurePosition="left">
                  <Text color="purple" element="h3" weight="medium">
                    {item.title}
                  </Text>
                </MediaObject>
              </Row>
              <Text element="p" flush>
                {item.copy}
              </Text>
            </Box>
          </Row>
        ))}
        <Button level="primary" full onClick={handleClick}>
          Start program
        </Button>
      </OnboardingView>
    </OnboardingLayout>
  )
}
