import { DateTime } from 'luxon'
import React from 'react'
import AdminLessonRegimenUpdateModal from 'admin/components/AdminLessonRegimenUpdateModal'
import * as adminApiRequest from 'admin/libs/admin-api-request'
import Button from 'components/Button'
import Heading from 'components/Heading'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import notifications from 'libs/notifications'
import AdminLessonRegimensWeek from './components/AdminLessonRegimensWeek'

type Props = {
  isOpen: boolean
  onClose: () => void
  onUpdate: () => void
  startOfWeek: DateTime
}
export default function AdminLessonRegimensEditModal({
  isOpen,
  onClose,
  startOfWeek,
  onUpdate: handleScheduleUpdate,
}: Props) {
  const [lessonRegimens, setLessonRegimens] = React.useState(null)
  const [showNewModal, setShowNewModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  async function getRegimenList() {
    const response = await adminApiRequest.getLessonRegimens()
    if (response && response.statusCode === 200) {
      setLessonRegimens(response.data)
    }
  }

  React.useEffect(() => {
    getRegimenList()
  }, [])

  function handleUpdate() {
    getRegimenList()
  }

  function handleOpenModal() {
    setShowNewModal(true)
  }

  function handleCloseModal() {
    setShowNewModal(false)
    getRegimenList()
  }

  async function handleSubmit() {
    notifications.notifyInfo('Generating from series. Please hold for one eternity.')
    setLoading(true)
    const response = await adminApiRequest.createLessonEntitiesForLessonRegimensForDateRange(
      startOfWeek,
      startOfWeek.plus({
        days: 6,
        hours: 23,
        minutes: 59,
      })
    )
    if (response.statusCode === 200) {
      notifications.notifySuccess('Generated New Classes from Series!')
      if (handleScheduleUpdate) handleScheduleUpdate()
      handleCloseModal()
    } else {
      notifications.notifyError(response.message)
    }
    setLoading(false)
  }

  return (
    <Modal size="max" isOpen={isOpen} onRequestClose={onClose}>
      <ModalBox size="small">
        <Heading level={1}>Create classes from recurring</Heading>
        <Heading level={3}>{`(For week of ${startOfWeek?.toLocaleString()})`}</Heading>
        <Button onClick={handleOpenModal}>Add New Recurring Class</Button>
        <AdminLessonRegimensWeek list={lessonRegimens} onUpdate={handleUpdate} />
        <AdminLessonRegimenUpdateModal
          propsLessonRegimen={null}
          isOpen={showNewModal}
          onClose={handleCloseModal}
          onUpdate={handleUpdate}
        />
        <Button disabled={loading} onClick={handleSubmit}>
          CREATE
        </Button>
      </ModalBox>
    </Modal>
  )
}
