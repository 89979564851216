import React from 'react'
import ProgramFirstLessonLesson from 'app/components/ProgramFirstLessonLesson'
import ProgramFirstLessonMovements from 'app/components/ProgramFirstLessonMovements'
import Box from 'components/Box'
import BoxSection from 'components/BoxSection'
import Button from 'components/Button'
import ButtonsFloating from 'components/ButtonsFloating'
import Container from 'components/Container'
import Heading2 from 'components/Heading2'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Tag from 'components/Tag'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import formatDate from 'libs/format-date'
import { useMovementsForLesson } from 'libs/lesson-query'
import { useProgram } from 'libs/program-query'
import { ReactComponent as BadgeSvg } from './images/badge.svg'

export default function ProgramFirstPostLesson() {
  useEventPageVisited('Program post first lesson')
  const globalContext = useGlobalContext()
  const { data: programData, isLoading: isProgramLoading } = useProgram({
    revalidateOnFocus: false,
  })
  const program = programData?.data
  const lessonId = program?.firstLessonId
  const { data: movementsForLessonData, isLoading: isMovementsForLessonLoading } =
    useMovementsForLesson(lessonId, {
      dependencies: [programData],
      revalidateOnFocus: false,
    })
  const movements = movementsForLessonData?.data

  return (
    <View color="grey" className="ProgramFirstPostLesson">
      <Container flush size="small">
        <Row size="small">
          <Text align="center" element="header">
            <Row size="smallx">
              <BadgeSvg aria-label="1st class" />
            </Row>
            <Row size="small">
              <Tag casing="normal" color="purple500">
                {formatDate.display(new Date())}
              </Tag>
            </Row>
            <Heading2 level={2} levelStyle={4} balance>
              Amazing job, <Text color="purple">{globalContext.user?.firstName}</Text>! First class?
              Check.
            </Heading2>
          </Text>
        </Row>
        <Row size="smallx">
          <Box color="white" size="small">
            <BoxSection>
              {!isProgramLoading && <ProgramFirstLessonLesson data={program?.firstLesson} />}
            </BoxSection>
            <BoxSection>
              {isMovementsForLessonLoading ? (
                <Loader overlay={false} />
              ) : (
                <>
                  <Text element="p">Below are some of the movements we went over in class:</Text>
                  <ProgramFirstLessonMovements data={movements} />
                </>
              )}
            </BoxSection>
          </Box>
        </Row>
        <ButtonsFloating>
          <Button full to={paths.PROGRAM_FIRST_POST_LESSON_FEEDBACK}>
            Share class feedback
          </Button>
        </ButtonsFloating>
      </Container>
    </View>
  )
}
