import React from 'react'
import SettingsHeaderBack from 'app/components/settings/SettingsHeaderBack'
import Subscription from 'app/components/settings/Subscription'
import Container from 'components/Container'
import View from 'components/View'
import { useGlobalContext } from 'contexts/GlobalContext'
import EnterpriseSubscription from 'enterprise/components/EnterpriseSubscription'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function SettingsSubscription() {
  useEventPageVisited('Settings subscription')
  const globalContext = useGlobalContext()

  const commonProps = {
    title: 'Plan',
  }

  return (
    <View className="SettingsSubscription">
      <SettingsHeaderBack />
      <Container flush size="medium">
        <Subscription {...commonProps} />
        <EnterpriseSubscription {...commonProps} />
      </Container>
    </View>
  )
}
