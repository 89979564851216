/* eslint-disable no-else-return */
import React from 'react'
import Button from 'components/Button'

function LowerBodyMovementDisplay({ userProfile, bestMatchPrograms, currentProgram, onSelect }) {
  const getColor = (number: number, isUser: boolean) => {
    let value = number
    if (number < 0) value = 0
    if (number > 1) value = 1
    const halfway = isUser ? 0.25 : 0.05
    const redValue = value < halfway ? 255 : Math.round(255 - (value - halfway) * 2 * 255)
    const greenValue = value < halfway ? Math.round(value * 2 * 200) : 200
    return `rgb(${redValue}, ${greenValue}, 0)`
  }

  if (!userProfile.lowerBodyMovementProfile || !bestMatchPrograms) {
    return null
  }

  return (
    <table>
      <thead>
        <tr>
          <th />
          {userProfile.lowerBodyMovementProfile.map((value, index) => (
            <th key={index}>{index + 1}</th>
          ))}
          <th>Average Intensity</th>
          <th>Average Class Duration</th>
          <th>Match Confidence Score</th>
          <th>Select</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>User Profile</td>
          {userProfile.lowerBodyMovementProfile.map((value, index) => (
            <td key={index} style={{ backgroundColor: getColor(value, true) }}>
              {Number.parseFloat(value).toFixed(2)}
            </td>
          ))}
        </tr>
        <tr>
          <td>😊</td>
        </tr>
        <tr>
          <td>Programs</td>
        </tr>
        <tr>
          <td>😊</td>
        </tr>
        <tr />
        {bestMatchPrograms.map((program) => (
          <tr
            style={
              program.title === currentProgram?.title ? { border: '3px solid rgb(0, 0, 0)' } : {}
            }>
            <td>{program.title}</td>
            {program.firstBlockLowerBodyMovementProfile.map((value, index) => (
              <td
                key={index}
                style={{
                  backgroundColor: getColor(value / program.firstBlockEffectiveDuration, false),
                }}>
                {(value / program.firstBlockEffectiveDuration).toFixed(2)}
              </td>
            ))}
            <td>{Number.parseFloat(program.firstBlockAverageIntensity).toFixed(2)}</td>
            <td>{Number.parseFloat(program.firstBlockAverageLessonDuration).toFixed(2)}</td>
            <td>{Number.parseFloat(program.matchConfidenceScore).toFixed(2)}</td>
            <td>
              <Button onClick={() => onSelect(program)}>Select</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default LowerBodyMovementDisplay
