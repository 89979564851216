import _ from 'lodash'
import qs from 'qs'
import { SWROptions, useSWRGet } from './swr'

type ChallengeSearchParameters = {
  type?: string
  tag?: string
  isChallengeActive?: boolean
}
export function useUserChallenges(parameters: ChallengeSearchParameters, options: SWROptions) {
  const path = 'challenges/user'
  return useSWRGet(
    !_.isEmpty(parameters)
      ? `${path}?${qs.stringify({
          ...parameters,
        })}`
      : path,
    options
  )
}

export function useLatestChallengeForCollectionId(collectionId: string, options: SWROptions) {
  return useSWRGet(`challengeForCollection/${collectionId}`, options)
}
