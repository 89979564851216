import React from 'react'
import Text from 'components/Text'
import View from 'components/View'
import statusCodes from 'constants/status-codes'
import * as userApiRequest from 'libs/user-api-request'

type Props = {
  unsubscribeId: string
}

export default function Unsubscribe({ unsubscribeId }: Props) {
  const [text, setText] = React.useState('')

  React.useEffect(() => {
    if (!unsubscribeId) return

    async function unsubscribe() {
      const response = await userApiRequest.unsubscribe(unsubscribeId)

      if (response.data.status !== statusCodes.SUCCESS) return

      setText(response.data.message)
    }
    unsubscribe()
  }, [unsubscribeId])

  return (
    <View className="Unsubscribe">
      <Text align="center" size="xlarge">
        {text}
      </Text>
    </View>
  )
}
