import classNames from 'classnames'
import { navigate } from 'gatsby'
import React from 'react'
import CheckinForm from 'components/CheckinForm'
import Container from 'components/Container'
import ModalBox from 'components/ModalBox'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as checkinApiRequest from 'libs/checkin-api-request'
import notifications from 'libs/notifications'
import user from 'libs/user'
import CheckinTracker from 'libs/checkins/checkin-tracker'

type Props = {
  isRestream?: boolean
  liveLessonId?: string
  liveLessonStreamId?: string
  onSuccess: () => void
} & Component

export default function LessonFeedback({
  className,
  isRestream = false,
  liveLessonId,
  liveLessonStreamId,
  onSuccess,
}: Props) {
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker | null>(null)
  const isLiveLesson = !!liveLessonStreamId && !!liveLessonId
  const lessonId = liveLessonId || globalContext.lesson?.id
  const lessonTitle = globalContext.lesson?.videoTitle

  React.useEffect(() => {
    globalContext.analytics?.trackEvent(events.START_FEEDBACK, {
      classId: lessonId,
      isExplore: window.location?.hash === '#explore',
      isRestream,
      lessonName: lessonTitle,
    })
  }, [globalContext.analytics, isRestream, lessonId, lessonTitle])

  React.useEffect(() => {
    async function getCheckin() {
      const afterClassCheckinResponse = await checkinApiRequest
        .getUserCheckin({
          type: isRestream ? 'after_restream' : 'after_class',
          code: isLiveLesson
            ? 'after_live_lesson'
            : isRestream
              ? 'restream_after_class'
              : 'default_after_class',
          activeOnly: true,
          objectId: isLiveLesson ? liveLessonStreamId : lessonId,
          createIfNotExists: true,
        })
        .catch((error: any) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: 'lesson feedback',
            type: isRestream ? 'after_restream' : 'after_class',
          })
          notifications.notifyError()
        })

      const afterClassCheckin = afterClassCheckinResponse.data

      if (!afterClassCheckin) return

      setCheckinTracker(
        new CheckinTracker(globalContext.user?.id, afterClassCheckin, globalContext.analytics)
      )
    }
    getCheckin()
  }, [
    globalContext.user?.id,
    isLiveLesson,
    isRestream,
    lessonId,
    liveLessonStreamId,
    globalContext.analytics,
  ])

  function handleSuccess(answers) {
    notifications.notifySuccess('Thanks for your feedback!')

    const isExplore = window.location?.hash === '#explore'

    globalContext.analytics?.trackEvent(events.COMPLETE_FEEDBACK, {
      answers: JSON.stringify(answers),
      classId: globalContext.lesson?.id,
      isExplore,
      isRestream,
      lessonName: globalContext.lesson?.videoTitle,
    })

    if (isExplore || !user.canChangeProgram(globalContext.user)) {
      onSuccess()
      return
    }

    if (isRestream) {
      navigate(paths.LIVE_LESSONS)
      return
    }

    onSuccess()
  }

  return (
    <ModalBox
      title={isRestream ? 'After rerun feedback' : 'After class feedback'}
      className={classNames('LessonFeedback', className)}>
      <Container flush size="small">
        <CheckinForm
          buttonProps={{
            children: 'Send feedback',
            color: 'purple',
            full: true,
          }}
          checkinTracker={checkinTracker}
          onSuccess={handleSuccess}
        />
      </Container>
    </ModalBox>
  )
}
