import classNames from 'classnames'
import React from 'react'
import Image from 'components/Image'
import MediaObject from 'components/MediaObject'
import Text from 'components/Text'
import type { Component, Lesson } from 'constants/types'
import * as lesson from 'libs/lesson'
import styles from './LessonTrainer.module.scss'

type Props = {
  data: Lesson
  showName?: boolean
  size?: 'medium' | 'small'
} & Component

export default function LessonTrainer({
  className,
  data,
  showName = true,
  size = 'medium',
  ...props
}: Props) {
  const componentClassNames = classNames(
    'LessonTrainer',
    styles.this,
    styles[`this---${size}`],
    className
  )

  const image = (
    <Image
      src={lesson.getTrainerImage(data.instructorName)}
      className={classNames(componentClassNames, styles.photo)}
    />
  )

  if (!showName) {
    return image
  }

  return (
    <MediaObject
      figure={<Image src={lesson.getTrainerImage(data.instructorName)} className={styles.photo} />}
      center
      size={size === 'small' ? 'xxsmall' : 'xsmall'}
      className={componentClassNames}>
      <Text color="black" size={size} weight="medium" {...props}>
        {data.instructorName}
      </Text>
    </MediaObject>
  )
}
