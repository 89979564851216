import classNames from 'classnames'
import React from 'react'
import SubscriptionPaymentMethodForm from 'app/components/SubscriptionPaymentMethodForm'
import ButtonWithModal from 'components/ButtonWithModal'
import * as events from 'constants/events'
import statusCodes from 'constants/status-codes'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import notifications from 'libs/notifications'
import * as subscriptionApiRequest from 'libs/subscription-api-request'

type Props = {
  children?: React.ReactNode
  onClick?: () => void
} & Component

export default function SubscriptionPaymentMethodChangeButton({
  children = 'Change payment information',
  className,
  onClick,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const [isDisabled, setIsDisabled] = React.useState(true)
  //const [showModal, setShowModal] = React.useState(false)

  function handleClick() {
    globalContext.analytics?.trackEvent(events.SUBSCRIPTION_PAYMENT_METHOD_CHANGE_BUTTON_CLICK)
    if (onClick) onClick()
  }

  async function submit(stripeToken) {
    const response = await subscriptionApiRequest
      .addDefaultCardForUser(stripeToken.id)
      .catch((error) => {
        console.error(error)
        notifications.notifyError('Sorry, something went wrong updating your payment method')
      })

    if (response.code === statusCodes.SUCCESS) {
      globalContext.update({
        user: {
          ...globalContext.user,
          paymentMethod: response.data.card,
        },
      })

      notifications.notifySuccess('Card updated successfully')
      //setShowModal(false)
    } else {
      notifications.notifyError('Something went wrong. Please try again later.')
    }
  }

  function handlePaymentMethodFormValidityChange(isValid: boolean) {
    setIsDisabled(!isValid)
  }

  function handlePaymentMethodFormSubmit() {
    setIsDisabled(true)
  }

  function handlePaymentMethodFormSuccess(stripeToken) {
    submit(stripeToken)
  }

  const paymentMethodFormId = 'updateCard'

  if (!globalContext.user.defaultStripePaymentSource) return null

  return (
    <ButtonWithModal
      {...props}
      //showModal={showModal}
      modalProps={{
        title: 'Update your card details',
        cancelText: 'Cancel',
        confirmButtonProps: {
          form: paymentMethodFormId,
          type: 'submit',
        },
        confirmText: 'Update',
        disableConfirm: isDisabled,
        size: 'small',
        // TODO: modal doesn't hide…
        //skipRequestCloseOnConfirm: true,
        // TODO: allow for no onConfirm
        onConfirm: () => {},
      }}
      modalType="confirm"
      modal={
        <SubscriptionPaymentMethodForm
          id={paymentMethodFormId}
          onSubmit={handlePaymentMethodFormSubmit}
          onSuccess={handlePaymentMethodFormSuccess}
          onValidityChange={handlePaymentMethodFormValidityChange}
        />
      }
      className={classNames('SubscriptionPaymentMethodChangeButton', className)}
      onClick={handleClick}>
      {children}
    </ButtonWithModal>
  )
}
