import classNames from 'classnames'
import React from 'react'
import Button from 'components/Button'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import user from 'libs/user'

type Props = Component

export default function SubscriptionChangeButton({
  children = 'Change plan',
  className,
  onClick,
  ...props
}: Props) {
  const globalContext = useGlobalContext()

  if (!user.canChangePlan(globalContext.user)) {
    return null
  }

  return (
    <Button
      {...props}
      to={paths.SETTINGS_SUBSCRIPTION_CHANGE}
      className={classNames('SubscriptionChangeButton', className)}>
      {children}
    </Button>
  )
}
