// TODO
import classNames from 'classnames'
import React from 'react'
import * as rdd from 'react-device-detect'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import { v4 as uuidv4 } from 'uuid'
import Box from 'components/Box'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Embed from 'components/Embed'
import LessonTrainer from 'components/LessonTrainer'
import LiveLessonTag from 'components/LiveLessonTag'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import { LessonStream } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as lessonApiRequest from 'libs/lesson-api-request'
import urlLib from 'libs/url'
import styles2 from '../../LiveRestreamLesson.module.scss'
import styles from './LiveRestreamLessonVideo.module.scss'
import { ReactComponent as PlaySvg } from './images/play.svg'
import { ReactComponent as ControlsFullscreenExitSvg } from './images/controls/fullscreen-exit.svg'
import { ReactComponent as ControlsFullscreenSvg } from './images/controls/fullscreen.svg'
import { ReactComponent as ControlsMuteSvg } from './images/controls/mute.svg'
import { ReactComponent as ControlsMutedSvg } from './images/controls/muted.svg'

const MAX_PLAY_INTERVAL = 60 // seconds before we save data

type Props = {
  className: string
  classTimeDifferenceInSeconds: number
  data: LessonStream
  onEnd: any
  thumbnail: string
  url: string
}

export default function LiveRestreamLessonVideo({
  className,
  classTimeDifferenceInSeconds,
  data,
  onEnd,
  thumbnail,
  url,
}: Props) {
  const globalContext = useGlobalContext()
  const [complete] = React.useState(false)
  const [isFullscreen, setIsFullscreen] = React.useState(false)
  const [isMuted, setIsMuted] = React.useState(false)
  const [isIos, setIsIos] = React.useState(false)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [playing, setPlaying] = React.useState(false)
  const [playTimeElapsed, setPlayTimeElapsed] = React.useState(0)
  const [playTimeStart, setPlayTimeStart] = React.useState(0)
  const [sessionId, setSessionId] = React.useState(null)
  const [showControls, setShowControls] = React.useState(false)
  const playerContainerRef = React.useRef(null)
  const lesson = data.lesson

  React.useEffect(() => {
    const mobileSafariOrChrome =
      rdd.isMobileSafari || (rdd.isMobile && rdd.browserName === 'Chrome')
    setIsIos(mobileSafariOrChrome)
    setIsMuted(mobileSafariOrChrome)
    globalContext.analytics?.markPageVisited('LiveRestreamLesson', { isIos: mobileSafariOrChrome })
  }, [globalContext.analytics])

  React.useEffect(() => {
    setSessionId(uuidv4)
  }, [])

  const updateLessonStats = async () => {
    if (lesson && playTimeElapsed !== 0) {
      const videoLengthInSeconds = lesson.duration * 60
      const percentageWatched = ((100 * playTimeElapsed) / videoLengthInSeconds).toFixed(2)
      const updateData = {
        duration: playTimeElapsed,
        percentageWatched,
        status: 'completed',
        sessionId,
      }
      const result = await lessonApiRequest.updateLesson(updateData, {
        lessonId: lesson.id,
        lessonStreamId: data.id,
      })
      return result?.data
    }
    return null
  }

  const handleLessonPlaybackTracking = async () => {
    const { source } = urlLib.getQueryString()

    updateLessonStats().then(() => {
      // Load the latest user data for future tracking
      globalContext.updateUser()

      if (complete) {
        globalContext.analytics?.trackEvent(events.COMPLETE_LESSON, {
          classType1: lesson.classType?.[0],
          classType2: lesson.classType?.[1],
          isLiveLesson: false,
          isLiveRestream: true,
          isProgramLesson: false,
          lessonId: lesson.id,
          lessonName: lesson.videoTitle,
          lessonStreamId: data.id,
          lessonType: lesson.type,
          lessonUrl: lesson.videoLink,
          source,
          trainerName: lesson.instructorName,
        })
      }
    })
  }

  const handlePlaybackDataTracking = async () => {
    if (lesson) {
      handleLessonPlaybackTracking()
    }
  }

  const handleExitPlayer = React.useCallback(async () => {
    const exitTime = playTimeStart + playTimeElapsed

    setPlaying(false)
    // set*      playTimeStart, playTimeElapsed

    handlePlaybackDataTracking().then(() => {
      if (lesson) {
        globalContext.analytics?.trackEvent(events.EXIT_LESSON, {
          classType1: lesson.classType?.[0],
          classType2: lesson.classType?.[1],
          exitTime,
          isLiveLesson: false,
          isLiveRestream: true,
          isProgramLesson: false,
          lessonId: lesson.id,
          lessonName: lesson.videoTitle,
          lessonStream: data.id,
          lessonType: lesson.type,
          lessonUrl: lesson.videoLink,
          trainerName: lesson.instructorName,
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(
    () => () => {
      handleExitPlayer()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleExitPlayer]
  )

  const onProgress = (props) => {
    const playedSeconds = props && Math.ceil(props.playedSeconds)
    if (!playing && playedSeconds !== 0) {
      setPlaying(true)
      setPlayTimeStart(playedSeconds)
      setPlayTimeElapsed(0)
      return
    }
    const elapsedTime = playedSeconds - playTimeStart
    if (elapsedTime >= MAX_PLAY_INTERVAL) {
      handlePlaybackDataTracking().then(() => {
        setPlayTimeStart(playedSeconds)
        setPlayTimeElapsed(0)
      })
      return
    }
    setPlayTimeElapsed(elapsedTime)
  }

  const onEnded = () => {
    if (onEnd) {
      onEnd()
    }
  }

  const onPlay = () => {
    // do nothing
  }

  function handleOverlayDismissed() {
    setIsPlaying(!isIos)
    setPlaying(!isIos)
    setShowControls(true)
  }

  function handleMuteClick() {
    setIsMuted((prevState) => !prevState)
  }

  function handlePlayClick() {
    setIsMuted(false)
    setIsPlaying(true)
    setPlaying(true)

    globalContext.analytics?.trackEvent('Manual join live rerun', {
      classType1: lesson?.classType?.[0],
      classType2: lesson?.classType?.[1],
      isLiveLesson: false,
      isLiveRestream: true,
      isProgramLesson: false,
      lessonId: lesson?.id,
      lessonName: lesson?.videoTitle,
      lessonType: lesson?.type,
      lessonUrl: lesson?.videoLink,
      trainerName: lesson?.instructorName,
    })
  }

  let playerRef

  function handleOnReady() {
    playerRef.seekTo(classTimeDifferenceInSeconds)
  }

  function handleFullscreenClick() {
    setIsFullscreen(!isFullscreen)
    screenfull.toggle(playerRef.current)
  }

  const isStarted = classTimeDifferenceInSeconds > 0
  const isPast = classTimeDifferenceInSeconds / 60 > lesson.duration

  const controlsButtonProps = {
    color: 'white',
    level: 'text',
    className: styles['controls--button'],
  }

  if (showControls && isIos && !isPlaying) {
    globalContext.analytics?.trackEvent('View manual join live rerun button')
  }

  return (
    <div
      ref={playerContainerRef}
      className={classNames('LiveRestreamLessonVideo', styles.this, className)}>
      <Embed className={styles.embed}>
        <ReactPlayer
          url={url}
          light={thumbnail}
          playing={isPlaying}
          playsinline
          ref={(player) => {
            playerRef = player
          }}
          // enforce max volume to override if they somehow have player previously muted
          volume={isMuted ? 0 : 1}
          playIcon={
            isStarted && !isPast ? (
              <div className={styles2['video-overlay']} onClick={handleOverlayDismissed}>
                <Container flush size="xmedium">
                  <Box color="white" className={styles2.box}>
                    <Row size="small">
                      <Row size="xxsmall">
                        <MediaObject figure={<LiveLessonTag data={data} />} figurePosition="right">
                          <Row size="xxsmall">
                            <Text element="h2" size="large" weight="semibold">
                              About this session
                            </Text>
                          </Row>
                          <LessonTrainer data={lesson} />
                        </MediaObject>
                      </Row>
                      <Text element="p">{lesson.instructions}</Text>
                    </Row>
                    <Button color="yellow" full icon={<PlaySvg />} size="xlarge">
                      Join class
                    </Button>
                  </Box>
                </Container>
              </div>
            ) : undefined
          }
          onClickPreview={handleOverlayDismissed}
          onEnded={onEnded}
          onPlay={onPlay}
          onProgress={onProgress}
          onReady={handleOnReady}
        />
      </Embed>
      {showControls && (
        <>
          {isIos && !isPlaying && (
            <Button
              onClick={handlePlayClick}
              color="yellow"
              icon={<PlaySvg />}
              className={styles['play-button']}
              size="xlarge">
              Play
            </Button>
          )}
          {!isIos && (
            <Buttons align="right" className={styles.controls}>
              <Buttons size="small">
                <Button {...controlsButtonProps} onClick={handleMuteClick}>
                  {isMuted ? (
                    <ControlsMutedSvg aria-label="Unmute" />
                  ) : (
                    <ControlsMuteSvg aria-label="Mute" />
                  )}
                </Button>
                <Button {...controlsButtonProps} onClick={handleFullscreenClick}>
                  {isFullscreen ? (
                    <ControlsFullscreenExitSvg aria-label="Exit fullscreen" />
                  ) : (
                    <ControlsFullscreenSvg aria-label="Fullscreen" />
                  )}
                </Button>
              </Buttons>
            </Buttons>
          )}
        </>
      )}
    </div>
  )
}
