import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Carousel from 'components/Carousel'
import Image from 'components/Image'
import Link from 'components/Link'
import Row from 'components/Row'
import Tags from 'components/Tags'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component, Lesson } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as lesson from 'libs/lesson'
import styles from './ExploreLessons.module.scss'
import { ReactComponent as ExploreSvg } from './images/explore.svg'
import Box from '../Box'

type Props = {
  data: Lesson[]
} & Component

export default function ExploreLessons({ className, data }: Props) {
  const globalContext = useGlobalContext()

  if (_.isEmpty(data)) return null

  function handleViewAllClick() {
    globalContext.analytics?.trackEvent(events.DASHBOARD_LIVE_LESSONS_VIEW_ALL_CLICK)
  }

  const carouselOffset = 24

  return (
    <Box
      title="Explore on-demand classes"
      titleColor="pink"
      titleIcon={<ExploreSvg />}
      headerButtonEntity="all explore"
      headerButtonTo={paths.EXPLORE}
      onHeaderButtonClick={handleViewAllClick}
      flush="x"
      className={classNames('ExploreLessons', styles.this, className)}>
      <div className={styles.lessons}>
        <Carousel
          loop={false}
          navigationProps={{
            background: true,
          }}
          showNavigation
          slidesOffsetBefore={carouselOffset}
          slidesOffsetAfter={carouselOffset}
          slidesPerView="auto"
          spaceBetween={16}>
          {data.map((item) => (
            <Link to={`${paths.LESSON}${item.id}#explore`} key={item.id} className={styles.lesson}>
              <Row size="xsmall">
                <div className={styles['lesson--image-wrap']}>
                  <Image src={item.videoThumbnail} className={styles['lesson--image']} />
                  <div className={styles['lesson--image-overlay']}>
                    <Tags
                      data={[
                        _.startCase(item.type).replace(' ', '/'),
                        item.classMedium === lesson.MEDIUM_LIVE ? 'Past live' : undefined,
                        item.status === 'completed' ? 'Watched' : undefined,
                      ]}
                    />
                  </div>
                </div>
              </Row>
              <Row size="xxxsmall">
                <Text element="h3" weight="medium" className={styles['lesson--title']}>
                  {lesson.getProgramLessonTitle(item)}
                </Text>
              </Row>
              <Text size="small">
                {item.instructorName}
                {!_.isEmpty(item.classType) && (
                  <>
                    {' • '}
                    {item.classType.slice(0, 3).join(', ')}
                  </>
                )}
              </Text>
            </Link>
          ))}
          <Link to={paths.EXPLORE} className={styles['lessons--view-all']}>
            <Button color="white" faux icon="arrow-right" level="text">
              View all
            </Button>
          </Link>
        </Carousel>
      </div>
    </Box>
  )
}
