import React from 'react'
import Button from 'components/Button'
import ButtonsFloating from 'components/ButtonsFloating'
import Copy from 'components/Copy'
import ListNumbers from 'components/ListNumbers'
import Text from 'components/Text'
import subscriptions from 'libs/subscriptions'
import OnboardingView from 'onboarding/components/OnboardingView'

type Props = {
  onNext: () => void
}

export default function OnboardingEnterpriseIneligible({ onNext }: Props) {
  return (
    <OnboardingView small="Sorry" title="We couldn’t verify your eligibility">
      <Copy>
        <Text element="p">
          Here are some potential reasons why we weren’t able to verify your eligibility:
        </Text>
        <ListNumbers
          data={[
            'The information you entered is different from what your health plan has on file.',
            'Your health plan does not offer access to Bold.',
          ]}
        />
        <Text element="h4">Don’t worry, you can still get started with Bold.</Text>
        <Text element="p">
          You can complete your account and access a free {subscriptions.TRIAL_DAYS}-day trial of
          Bold Premium.
        </Text>
        <Text element="h4">Need more help verifying your eligibility for Bold?</Text>
        <Text element="p">
          If you think you have access to bold through your health plan, please call the member
          services number on the back of your health plan ID card and speak with your health plan to
          verify.
        </Text>
      </Copy>
      <ButtonsFloating>
        <Button full size="large" onClick={onNext}>
          Continue
        </Button>
      </ButtonsFloating>
    </OnboardingView>
  )
}
