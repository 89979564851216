import classNames from 'classnames'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import Button from 'components/Button'
import ImageBackground from 'components/ImageBackground'
import MediaObject from 'components/MediaObject'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import type { Component } from 'constants/types'
import styles from './Box.module.scss'

type Props = {
  children: React.ReactNode
  flush?: boolean | 'x'
  footer?: React.ReactNode
  headerButtonEntity: string
  headerButtonTo: string
  image?: string
  onHeaderButtonClick: () => void
  title: string
  titleColor: 'blue' | 'purple' | 'pink'
  titleIcon: React.ReactNode
} & Component

export default function Box({
  children,
  className,
  flush,
  footer,
  headerButtonEntity,
  headerButtonTo,
  image,
  onHeaderButtonClick,
  title,
  titleColor,
  titleIcon,
}: Props) {
  return (
    <section
      className={classNames(
        'Box',
        styles.this,
        flush && flush !== 'x' && styles['this---flush'],
        flush && styles[`this---flush---${flush}`],
        image && styles['this---image'],
        className
      )}>
      <Text element="header" className={styles.header}>
        <GatsbyLink to={headerButtonTo} onClick={onHeaderButtonClick}>
          <MediaObject
            figure={
              <Button faux level="text" className={styles['header--button']}>
                View {headerButtonEntity}
              </Button>
            }
            center
            figurePosition="right"
            size="small">
            <TextWithIcon
              icon={titleIcon}
              color={titleColor}
              element="h2"
              size="large"
              weight="semibold"
              className={styles.title}>
              {title}
            </TextWithIcon>
          </MediaObject>
        </GatsbyLink>
      </Text>
      <div className={classNames('Box--body', styles.body)}>
        <div className={styles['body--inner']}>{children}</div>
        {image && <ImageBackground src={image} className={styles.image} />}
      </div>
      {footer && (
        <Text element="footer" className={classNames('Box--footer', styles.footer)}>
          {footer}
        </Text>
      )}
    </section>
  )
}
