import _ from 'lodash'
import React from 'react'
import LessonVideo from 'app/components/LessonVideo'
import ProgramFirstLessonLesson from 'app/components/ProgramFirstLessonLesson'
import Box from 'components/Box'
import Button from 'components/Button'
import Copy from 'components/Copy'
import Heading2 from 'components/Heading2'
import Modal, { type ModalProps } from 'components/Modal'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Lesson, LessonMovement } from 'constants/types'

const MOVEMENT_TYPES = {
  'sitting': 'seated',
  'sitting/floor': 'seated',
  'sitting/standing': 'seated & standing',
  'standing': 'standing',
  'standing/floor': 'standing',
}

type Props = {
  data: LessonMovement | undefined
  lesson: Lesson
} & Partial<ModalProps>

export default function ProgramFirstLessonMovementModal({
  data,
  lesson,
  onRequestClose,
  ...props
}: Props) {
  const movementData = {
    ...data,
    id: data?.educationLessonId,
  }
  return (
    <Modal {...props} className="ProgramFirstLessonMovementModal" onRequestClose={onRequestClose}>
      <ModalBox align="left">
        <Row size="small">
          {
            // closing modal with video was throwing an error about lesson.duration
            !_.isEmpty(data) && <LessonVideo buttonText="Start tutorial" lesson={movementData} />
          }
        </Row>
        <Row size="xxsmall">
          <Text casing="upper" color="purple" element="h2" weight="medium">
            {MOVEMENT_TYPES[data?.type]} movement
          </Text>
        </Row>
        <Heading2 level={2} levelStyle={4}>
          {data?.movementName}
        </Heading2>
        <Row size="small">
          <Copy>
            <Text element="h3">How to perform</Text>
            <Text element="p">{data?.howDescription}</Text>
            <Text element="h3">Why it’s important</Text>
            <Text element="p">{data?.whyDescription}</Text>
          </Copy>
        </Row>
        {!_.isEmpty(lesson) && (
          <>
            <Row size="smallx">
              <Heading2 level={3} levelStyle={5} flush>
                Try this movement in class
              </Heading2>
            </Row>
            <Box size="xsmall">
              <Row size="smallx">
                <ProgramFirstLessonLesson data={lesson} />
              </Row>
              <Button color="yellow" full onClick={onRequestClose}>
                Start class
              </Button>
            </Box>
          </>
        )}
      </ModalBox>
    </Modal>
  )
}
