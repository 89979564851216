const CHALLENGE_2021_08_EMAILS: string[] = [
  'alvarez.olga@hotmail.com',
  'cdnanne@q.com',
  'dianatopia@yahoo.com',
  'dsherlin02@gmail.com',
  'grammars@yahoo.com',
  'ilamehta47@gmail.com',
  'kkbj3@att.net',
  'lisatalk53@aol.com',
  'mjones609@verizon.net',
  'norsato317@comcast.net',
  'nvburns38@gmail.com',
  'susanreldridge@gmail.com',
  'syar48@mail.com',
  'taughblvd@aol.com',
]

const CHALLENGE_2021_10_EMAILS: string[] = [
  'ahatfield1@live.com',
  'alpinehealingarts@gmail.com',
  'annemckinney1953@gmail.com',
  'bblanton091@gmail.com',
  'betty.j.choate@gmail.com',
  'bjavitz@gmail.com',
  'cathykoltz@hotmail.com',
  'cdnanne@q.com',
  'charles.chivakos@verizon.net',
  'clark.francesa@gmail.com',
  'cps0817@gmail.com',
  'dianalynn1255@yahoo.com',
  'dianatopia@yahoo.com',
  'dovboyk@aol.com',
  'dsherlin02@gmail.com',
  'effiearmstrong@cox.net',
  'ekielyjr@aol.com',
  'elainej213@aol.com',
  'fljoane@hotmail.com',
  'fredchavira85@gmail.com',
  'fuentes_graciela7454@yahoo.com',
  'grammars@yahoo.com',
  'grammynan2@gmail.com',
  'grandma2156@gmail.com',
  'hbhattmd@gmail.com',
  'hgbertelsen@aol.com',
  'hildejj@aol.com',
  'humke@aol.com',
  'jimlight@hanmail.net',
  'juniorjeg3@gmail.com',
  'kelburn@comcast.net',
  'kturout@gmail.com',
  'lfw132115@gmail.com',
  'lil_sylvia@msn.com',
  'lynpuff@shaw.ca',
  'mamalhoe@gmail.com',
  'mardek2@gmail.com',
  'mhalloran179@gmail.com',
  'mlchavira5@gmail.com',
  'mlrush@me.com',
  'momsweft4@gmail.com',
  'mpolk428@gmail.com',
  'mzmesq@aol.com',
  'nvburns38@gmail.com',
  'patmartin@myactv.net',
  'phillisjp@aol.com',
  'psm400@yahoo.com',
  'reizan4570@gmail.com',
  'robertreeslaw@att.net',
  'sallychase85@gmail.com',
  'sandhpsn@aol.com',
  'tomandkarenw@gmail.com',
  'vaitkusaime@yahoo.com',
  'vernkelly@cox.net',
  'wandahinds@gmail.com',
  'wmar59@yahoo.com',
]

export default {
  CHALLENGE_2021_08_EMAILS,
  CHALLENGE_2021_10_EMAILS,
}
