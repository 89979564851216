import React from 'react'
import Image from 'components/Image'
import LessonTrainer from 'components/LessonTrainer'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Lesson } from 'constants/types'
import styles from './ProgramFirstLessonLesson.module.scss'

type Props = {
  data: Lesson
}

export default function ProgramFirstLessonLesson({ data }: Props) {
  return (
    <MediaObject
      figure={<Image src={data.videoThumbnail} className={styles.image} />}
      center
      size="xsmall"
      className="ProgramFirstLessonLesson">
      <Text color="black" element="div" weight="medium">
        <Row size="xxsmall">
          <Text element="h3">{data.videoTitle}</Text>
        </Row>
        <LessonTrainer data={data} size="small" />
      </Text>
    </MediaObject>
  )
}
