import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import React from 'react'
import type { Component } from 'constants/types'
import { useActiveProgramProgress, useProgram } from 'libs/program-query'
import styles from './ProgramProgress.module.scss'

type Props = {
  color?: 'purple'
} & Component

export default function ProgramProgress({ className, color = 'purple' }: Props) {
  const { data: programData } = useProgram()
  const { data: programProgressData } = useActiveProgramProgress()
  const program = programData?.data
  const programProgress = programProgressData?.data

  if (program == null || programProgress == null) {
    return null
  }

  return (
    <div
      className={classNames(
        'ProgramProgress',
        styles.this,
        color && styles[`this---${color}`],
        className
      )}>
      <div className={styles.bar}>
        <div
          style={{
            transform: `translateX(-${100 - (programProgress.percentComplete ?? 0)}%)`,
          }}
          className={styles['bar--fill']}
        />
      </div>
      <div className={styles.markers}>
        {program.exerciseProgram?.exerciseBlocks.map((item, index) => (
          <Tippy key={index} content={`Section ${index + 1}`}>
            <div
              className={classNames(
                styles.marker,
                index === program.currentBlockIndex && styles['marker---current'],
                index > program.currentBlockIndex && styles['marker---future']
              )}
            />
          </Tippy>
        ))}
      </div>
    </div>
  )
}
