import { DateTime } from 'luxon'
import React from 'react'
import Text from 'components/Text'
import { LessonStream } from 'constants/types'
import styles from './AdminLessonStreamsDay.module.scss'
import AdminLessonStreamsItem from '../AdminLessonStreamsItem'

type Props = {
  dateTime: DateTime
  onUpdate: () => void
  scheduleForDay: Array<LessonStream>
}

export default function AdminLessonStreamsDay({
  dateTime,
  onUpdate: handleUpdate,
  scheduleForDay,
}: Props) {
  return (
    <div className={styles.box}>
      <Text element="h2" weight="medium" className={styles.name}>
        {dateTime.weekdayShort} {dateTime.month}/{dateTime.day}
      </Text>
      {scheduleForDay.map((lessonStream) => (
        <AdminLessonStreamsItem lessonStream={lessonStream} onUpdate={handleUpdate} />
      ))}
    </div>
  )
}
