import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Container from 'components/Container'
import Head from 'components/Head'
import HeaderSubheader from 'components/HeaderSubheader'
import Image from 'components/Image'
import Loader from 'components/Loader'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import type { LessonMovement } from 'constants/types'
import useEventPageVisited from 'hooks/use-event-page-visited'
import { useUserMovements } from 'libs/movement-query'
import styles from './Movements.module.scss'

export default function Movements() {
  useEventPageVisited('Movements')
  const { data: movementsData, isLoading: isMovementsLoading } = useUserMovements()

  function handleClick(data: LessonMovement) {
    navigate(`${paths.MOVEMENTS}${data.movementId}/`, {
      state: {
        movement: data,
      },
    })
  }

  return (
    <div className="Movements">
      <Head bodyAttributes={{ class: 'body---grey body---has-subheader' }} />
      <HeaderSubheader>
        <Text element="h1" size="xlarge" weight="medium">
          My movements
        </Text>
      </HeaderSubheader>
      <View color="grey">
        <Container flush size="xxlarge">
          {isMovementsLoading ? (
            <Loader overlay={false} />
          ) : (
            <div className={styles.movements}>
              {movementsData.data.map((item) => (
                <button type="button" className={styles.movement} onClick={() => handleClick(item)}>
                  <Text element="figure" align="left" key={item.movementId}>
                    <Image
                      src={item.movement.educationLesson.videoThumbnail}
                      className={styles['movement--photo']}
                    />
                    <Text
                      color="black"
                      element="figcaption"
                      weight="medium"
                      className={styles['movement--body']}>
                      {item.movement.name}
                    </Text>
                  </Text>
                </button>
              ))}
            </div>
          )}
        </Container>
      </View>
    </div>
  )
}
