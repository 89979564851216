import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import formatMoney from 'libs/format-money'
import subscriptions from 'libs/subscriptions'
import type { Plan } from 'libs/subscriptions'
import styles from './SubscriptionPlanPrice.module.scss'

type Props = {
  append?: string
  appendInline?: string
  data: number
  plan: Plan
  size?: 'large' | 'medium' | 'inherit'
} & Component

export default function SubscriptionPlanPrice({
  align = 'right',
  append,
  appendInline,
  className,
  data,
  plan,
  size = 'medium',
  subText,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const [price, setPrice] = React.useState(data)

  React.useEffect(() => {
    setPrice(data)
  }, [data])

  React.useEffect(() => {
    if (_.isEmpty(globalContext.coupon)) {
      setPrice(data)
      return
    }

    if (
      globalContext.coupon.metadata?.extends_free_trial &&
      globalContext.coupon.metadata?.duration_in_days === 365
    ) {
      setPrice(0)
      return
    }

    setPrice(subscriptions.calculateAmountInCentsWithCoupon(data, globalContext.coupon, plan))
  }, [data, globalContext.coupon, plan])

  const isFree = price === 0
  const body = isFree ? 'Free' : formatMoney.amount(price)

  let priceSize = isFree ? 'large' : 'xxlarge'

  if (size === 'large') {
    priceSize = 'xxlarge'
  }

  return (
    <Text
      color={size === 'inherit' ? props.color : undefined}
      weight={size === 'inherit' ? props.weight : undefined}
      className={classNames('SubscriptionPlanPrice', className)}>
      <Text
        align={align}
        //element="p"
        //flush
        size={size !== 'inherit' ? priceSize : undefined}>
        {!isFree && (
          <Text
            element={size === 'inherit' ? undefined : 'sup'}
            className={size === 'inherit' ? undefined : styles.currency}>
            $
          </Text>
        )}
        {body}
        {appendInline}
      </Text>
      {append && (
        <Text {...props}>
          {size === 'inherit' ? '' : ' '}
          {append}
        </Text>
      )}
      {subText && (
        <Text {...props}>
          {size === 'inherit' ? '' : ' '}
          {subText}
        </Text>
      )}
    </Text>
  )
}
