import type { ExerciseProgram } from 'constants/types'

function getProgramTitle(program: ExerciseProgram) {
  return program?.external_name || program?.externalName || program?.title
}

export default {
  CLASS_TOO_EASY: 'easy',
  CLASS_TOO_HARD: 'hard',
  SITTING_DIFFICULTY_RESPONSE: '1',
  STANDING_DIFFICULTY_RESPONSE: '2',

  getProgramTitle,
}
