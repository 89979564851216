import _ from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import * as adminApiRequest from 'admin/libs/admin-api-request'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Heading from 'components/Heading'
import Text from 'components/Text'
import View from 'components/View'
import AdminLessonRegimensEditModal from './components/AdminLessonRegimensEditModal'
import AdminLessonStreamsWeek from './components/AdminLessonStreamsWeek'
import AdminLiveStreamCreateModal from './components/AdminLiveStreamCreateModal'
import AdminRerunStreamCreateModal from './components/AdminRerunStreamCreateModal'

export default function AdminLessonStreams() {
  const [startDateTime, setStartDateTime] = React.useState(null)
  const [lessonStreams, setLessonStreams] = React.useState([])
  const [showLessonRegimenEditModal, setShowLessonRegimenEditModal] = React.useState(false)
  const [showCreateOneoffLiveModal, setShowCreateOneoffLiveModal] = React.useState(false)
  const [showCreateOneoffRerunModal, setShowCreateOneoffRerunModal] = React.useState(false)

  async function getLessonStreamsForDate(dateTime: DateTime) {
    const response = await adminApiRequest.getLessonStreamsForDates(
      dateTime.toISO(),
      dateTime.plus({ weeks: 1 }).toISO()
    )
    if (response && response.statusCode === 200) {
      setLessonStreams(response.data)
    }
  }

  React.useEffect(() => {
    setStartDateTime(DateTime.now().setZone('America/Los_Angeles').startOf('week'))
  }, [])

  React.useEffect(() => {
    if (_.isNil(startDateTime)) return
    getLessonStreamsForDate(startDateTime)
  }, [startDateTime])

  const handleChangeWeek = (numberWeekChange: number) => {
    if (!startDateTime) return
    setStartDateTime(startDateTime.plus({ weeks: numberWeekChange }))
  }

  const handleUpdate = () => {
    getLessonStreamsForDate(startDateTime)
    setShowLessonRegimenEditModal(false)
    setShowCreateOneoffLiveModal(false)
    setShowCreateOneoffRerunModal(false)
  }

  return (
    <div className="AdminLessonStreams">
      <View>
        <Heading level={1}>Live / rerun schedule</Heading>
        <Buttons>
          <Button
            onClick={() => {
              handleChangeWeek(-1)
            }}>
            Last Week
          </Button>
          <Button
            onClick={() => {
              handleChangeWeek(1)
            }}>
            Next Week
          </Button>
        </Buttons>
        <br />
        {lessonStreams.length > 0 && (
          <Text color="red" weight="semibold">
            WARNING: CREATING MORE LESSONS FROM SERIES MAY LEAD TO DUPLICATES
          </Text>
        )}
        <Buttons>
          <Button
            color={lessonStreams.length > 0 ? 'red' : null}
            onClick={() => {
              setShowLessonRegimenEditModal(true)
            }}>
            Generate schedule from series
          </Button>
          <Button
            onClick={() => {
              setShowCreateOneoffLiveModal(true)
            }}>
            Create oneoff Live class
          </Button>
          <Button
            onClick={() => {
              setShowCreateOneoffRerunModal(true)
            }}>
            Create oneoff Rerun class
          </Button>
        </Buttons>
        {startDateTime && (
          <AdminLessonStreamsWeek
            lessonStreams={lessonStreams}
            startOfWeek={startDateTime}
            onUpdate={handleUpdate}
          />
        )}
      </View>
      <AdminLessonRegimensEditModal
        isOpen={showLessonRegimenEditModal}
        onClose={() => setShowLessonRegimenEditModal(false)}
        onUpdate={handleUpdate}
        startOfWeek={startDateTime}
      />
      <AdminLiveStreamCreateModal
        isOpen={showCreateOneoffLiveModal}
        onClose={() => setShowCreateOneoffLiveModal(false)}
        onUpdate={handleUpdate}
      />
      <AdminRerunStreamCreateModal
        isOpen={showCreateOneoffRerunModal}
        onClose={() => setShowCreateOneoffRerunModal(false)}
        onUpdate={handleUpdate}
      />
    </div>
  )
}
