import classNames from 'classnames'
import React from 'react'
import TextWithIcon from 'components/TextWithIcon'
import type { Component } from 'constants/types'
import styles from './LessonTag.module.scss'
import { ReactComponent as CheckmarkSvg } from './images/checkmark.svg'

type Props = {
  title: string
} & Component

export default function LessonTag({ className, title }: Props) {
  return (
    <TextWithIcon
      icon={<CheckmarkSvg />}
      color="white"
      inline
      size="xsmall"
      weight="semibold"
      className={classNames('LessonTag', styles.this, className)}>
      {title}
    </TextWithIcon>
  )
}
