import classNames from 'classnames'
import React from 'react'
import Text, { type TextProps } from 'components/Text'
import styles from './Tag.module.scss'

type Props = {
  children: React.ReactNode
  color?: 'green' | 'green' | 'blue' | 'purple' | 'purple500' | 'purple100' | 'pink' | 'white'
  level?: 'primary' | 'secondary'
} & TextProps

export default function Tag({
  casing = 'upper',
  children,
  className,
  color = 'purple100',
  level = 'primary',
  size = 'small',
  weight = 'medium',
  ...props
}: Props) {
  if (!children) return null

  return (
    <Text
      {...props}
      align="center"
      casing={casing}
      color={level === 'secondary' ? color : 'white'}
      size={size}
      weight={weight}
      className={classNames(
        'Tag',
        styles.this,
        color && styles[`this---${color}`],
        styles[`this---${level}`],
        styles[`this---${size}`],
        className
      )}>
      {children}
    </Text>
  )
}
