import React from 'react'
import AdminLanding from 'admin/pages/AdminLanding'
import TrainerDashboard from 'admin/pages/TrainerDashboard'
import Dashboard from 'app/components/Dashboard'
import Program from 'app/components/Program'
import ProgramBasic from 'app/components/ProgramBasic'
import * as events from 'constants/events'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import user from 'libs/user'

export default function Landing() {
  useEventPageVisited(events.AUTHED_HOME_PAGE)
  const globalContext = useGlobalContext()

  if (user.isAdminUser(globalContext.user)) {
    return <AdminLanding />
  }

  if (user.isTrainerUser(globalContext.user)) {
    return <TrainerDashboard />
  }

  if (user.isFreeUser(globalContext.user)) {
    return <ProgramBasic />
  }

  if (!user.hasDashboard(globalContext.user)) {
    return <Program />
  }

  return <Dashboard />
}
