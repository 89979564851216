import classNames from 'classnames'
import React from 'react'
import LessonFeedback from 'app/components/LessonFeedback'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import styles from './PostLesson.module.scss'
import Congrats from './components/Congrats'

const STATUS_CONGRATS = 'congrats'
const STATUS_FEEDBACK = 'feedback'

type Props = {
  lesson: any
  lessonStreamId?: string
  onSuccess: () => void
} & Component

export default function PostLesson({
  className,
  lesson,
  lessonStreamId,
  onSuccess,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const [classMilestoneCount, setClassMilestoneCount] = React.useState(null)
  const [status, setStatus] = React.useState(STATUS_FEEDBACK)
  const isRestream = !!lessonStreamId

  React.useEffect(() => {
    if ([5, 10, 20].includes(globalContext.user?.allTimeClassesWatched)) {
      setClassMilestoneCount(globalContext.user?.allTimeClassesWatched)
    }
  }, [globalContext.user?.allTimeClassesWatched])

  React.useEffect(() => {
    async function getData() {
      if (classMilestoneCount) {
        setStatus(STATUS_CONGRATS)
      } else {
        // if no badge, skip the congrats stage!
        setStatus(STATUS_FEEDBACK)
      }
    }
    getData()
  }, [globalContext.user?.id, classMilestoneCount, isRestream, lesson.id])

  return (
    <Text
      {...props}
      align="center"
      element="div"
      className={classNames('PostLesson', styles.this, className)}>
      {status === STATUS_CONGRATS && classMilestoneCount && (
        <Congrats
          classMilestoneCount={classMilestoneCount}
          onRequestLessonFeedback={() => setStatus(STATUS_FEEDBACK)}
        />
      )}
      {status === STATUS_FEEDBACK && (
        <LessonFeedback isRestream={isRestream} liveLessonId={lesson.id} onSuccess={onSuccess} />
      )}
    </Text>
  )
}
