import React from 'react'
import * as adminApiRequest from 'admin/libs/admin-api-request'
import ButtonWithModal from 'components/ButtonWithModal'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import notifications from 'libs/notifications'

type Props = {
  children?: React.ReactNode
  userId: string
}

export default function AdminUsersDelete({ children = 'Delete account', userId }: Props) {
  const globalContext = useGlobalContext()

  async function deleteAccount() {
    const response = await adminApiRequest.deleteUser(userId)

    if (response.statusCode !== statusCodes.SUCCESS) {
      notifications.notifyError('Something went wrong')
      return
    }

    globalContext.analytics?.trackEvent('Admin deleted user', {
      admin: globalContext.user.email,
      affectedUserId: userId,
    })

    notifications.notifyAndRedirect({
      message: 'Successfully closed account',
      to: paths.ADMIN_USERS,
    })
  }

  function handleConfirm() {
    deleteAccount()
  }

  return (
    <ButtonWithModal
      modalProps={{
        cancelText: 'No',
        confirmButtonProps: {
          children: 'Yes, close',
          color: 'red',
        },
        size: 'xsmall',
        theme: 'danger',
        title: 'Are you sure you want to close your Bold account?',
        onConfirm: handleConfirm,
      }}
      modalType="confirm"
      className="AdminUsersDelete">
      {children}
    </ButtonWithModal>
  )
}
