import classNames from 'classnames'
import {
  addDays,
  endOfWeek,
  getDate,
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  startOfWeek,
  subDays,
} from 'date-fns'
import _ from 'lodash'
import React from 'react'
import Media from 'react-media'
import Row from 'components/Row'
import Text from 'components/Text'
import statusCodes from 'constants/status-codes'
import type { Component } from 'constants/types'
import formatDate from 'libs/format-date'
import notifications from 'libs/notifications'
import * as userApiRequest from 'libs/user-api-request'
import constants from 'styles/constants.module.scss'
import styles from './Calendar.module.scss'

type Props = Component

export default function Calendar({ className }: Props) {
  const [data, setData] = React.useState({})

  const getData = React.useCallback(async () => {
    const response = await userApiRequest.getActivityStatsForUser()

    if (response.code === statusCodes.SUCCESS) {
      setData(response.data)
    } else {
      notifications.notifyError('Sorry, something went wrong displaying your activity calendar')
    }
  }, [])

  React.useEffect(() => {
    getData()
  }, [getData])

  if (_.isNil(data)) return

  const today = new Date()
  const date30DaysBefore = subDays(today, 30)
  const dateEndOfWeek = endOfWeek(today, { weekStartsOn: 1 })
  const dayStatusArray = []
  let currentCalendarDate = startOfWeek(date30DaysBefore, { weekStartsOn: 1 })

  // Combine previous and current activity dates
  const activityDates = (data.prevActiveDays || []).concat(data.activeDays)

  while (isBefore(currentCalendarDate, dateEndOfWeek)) {
    const currentDate = new Date(currentCalendarDate)
    const dateObject = {
      date: getDate(currentCalendarDate),
      class: styles.day,
      fullDate: currentCalendarDate,
      isActive: false,
    }

    // if date is before teh 30 day period it's in the "past"
    if (isBefore(currentCalendarDate, date30DaysBefore)) {
      dateObject.class = classNames(styles.day, styles['day---past'])
    }

    // if the date is after today, it's in the futuer
    if (isAfter(currentCalendarDate, today)) {
      dateObject.class = classNames(styles.day, styles['day---future'])
    }

    // Check for activity on the current date
    const hasActivityForDate = activityDates.find((activeDay) =>
      isSameDay(new Date(activeDay?.date), currentDate)
    )

    if (hasActivityForDate) {
      dateObject.isActive = true
      dateObject.class = classNames(styles.day, styles['day---active'])
    }

    // now if the date is in the active days it's "active"
    dayStatusArray.push(dateObject)
    currentCalendarDate = addDays(currentCalendarDate, 1)
  }

  const weeks = [
    dayStatusArray.slice(0, 7),
    dayStatusArray.slice(7, 14),
    dayStatusArray.slice(14, 21),
    dayStatusArray.slice(21, 28),
    dayStatusArray.slice(28, 35),
    dayStatusArray.slice(35, 42),
  ]

  return (
    <Text align="center" element="div" className={classNames('Calendar', styles.this, className)}>
      <Media query={{ minWidth: constants.GT_TABLET }}>
        <Row size="small">
          <Text element="h3" size="xxlarge" weight="medium" className={styles.title}>
            {formatDate.MONTHS_OF_YEAR[today.getMonth()]}
          </Text>
        </Row>
      </Media>
      <div className={styles['table-wrap']}>
        <table className={styles.table}>
          <thead>
            <tr>
              {[
                ['Monday', 'M'],
                ['Tuesday', 'Tu'],
                ['Wednesday', 'W'],
                ['Thursday', 'Th'],
                ['Friday', 'F'],
                ['Saturday', 'Sa'],
                ['Sunday', 'Su'],
              ].map((item) => (
                <Text element="th" key={item[0]}>
                  <Media query={{ minWidth: constants.TABLET }}>
                    <>{item[0]}</>
                  </Media>
                  <Media query={{ maxWidth: constants.LT_TABLET }}>
                    <>{item[1]}</>
                  </Media>
                </Text>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeks
              .filter((item) => !_.isEmpty(item))
              .map((item, index) => (
                <tr key={index} className={styles.days}>
                  {item.map((dateObject) => (
                    <Text
                      color={isToday(new Date(dateObject.fullDate)) ? 'purple' : undefined}
                      element="td"
                      key={dateObject.date}
                      weight={isToday(new Date(dateObject.fullDate)) ? 'medium' : undefined}
                      className={classNames(
                        dateObject.class,
                        isToday(new Date(dateObject.fullDate)) && styles['day---today']
                      )}>
                      <span className={styles['day--number']}>{dateObject.date}</span>
                    </Text>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Text>
  )
}
