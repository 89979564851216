import { navigate } from 'gatsby'
import React from 'react'
import ProgramBuild from 'components/ProgramBuild'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function OnboardingProgramBuild() {
  useEventPageVisited('Onboarding program build')

  return (
    <ProgramBuild
      className="OnboardingProgramBuild"
      onComplete={() => navigate(paths.ONBOARDING_PROGRAM_INTRODUCTION, { replace: true })}
    />
  )
}
