import _ from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import Box from 'components/Box'
import Text from 'components/Text'
import { LessonStream } from 'constants/types'
import AdminLessonStreamsActionsModal from '../AdminLessonStreamsActionsModal'

type Props = {
  lessonStream: LessonStream
  onUpdate: () => void
}

export default function AdminLessonStreamsItem({ lessonStream, onUpdate: handleUpdate }: Props) {
  const [showEditModal, setShowEditModal] = React.useState(false)
  const {
    streamType,
    scheduledStartTs,
    endTs,
    status,
    lesson: { videoTitle, instructorName },
  } = lessonStream

  const handleOpenModal = () => {
    setShowEditModal(true)
  }

  const handleCloseModal = () => {
    setShowEditModal(false)
    if (handleUpdate) handleUpdate()
  }

  const scheduledDateTime = DateTime.fromISO(scheduledStartTs)

  let color = null
  let textColor = 'default'
  if (status === 'canceled') {
    color = 'grey'
  } else if (!_.isNil(endTs) && new Date(endTs) < new Date()) {
    color = 'purple100'
    textColor = 'black'
  } else {
    color = streamType === 'zoom' ? 'purple500' : 'purple'
    textColor = 'white'
  }

  return (
    <>
      <Box color={color} onClick={handleOpenModal}>
        <Text color={textColor} element="h3" size="xlarge" weight="semibold">
          {status === 'canceled' && 'CANCELED: '}
          {scheduledDateTime.toLocaleString(DateTime.TIME_SIMPLE)} {_.capitalize(streamType)}
        </Text>
        <br />
        <Text color={textColor} element="h3" size="large" weight="semibold">
          {videoTitle}
        </Text>
        <br />
        <Text color={textColor} element="h3">
          {instructorName}
        </Text>
      </Box>
      <AdminLessonStreamsActionsModal
        isOpen={showEditModal}
        lessonStream={lessonStream}
        onClose={handleCloseModal}
        onUpdate={handleUpdate}
      />
    </>
  )
}
