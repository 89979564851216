import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import ProgramFirstLessonLesson from 'app/components/ProgramFirstLessonLesson'
import CheckinSingleForm from 'components/CheckinSingleForm'
import Container from 'components/Container'
import Head from 'components/Head'
import HeaderSubheader from 'components/HeaderSubheader'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as cookie from 'libs/cookie'
import notifications from 'libs/notifications'
import { useProgram } from 'libs/program-query'
import programs from 'libs/programs'
import CheckinTracker from 'libs/checkins/checkin-tracker'

export default function ProgramFirstPostLessonFeedback() {
  useEventPageVisited('Program post first lesson feedback')
  const globalContext = useGlobalContext()
  const userId = cookie.getCookie(storage.UID_KEY)
  const { data: programData, isLoading: isProgramLoading } = useProgram(userId, {
    revalidateOnFocus: false,
  })
  const program = programData?.data
  const lessonId = program?.firstLessonId
  const [checkinTracker, setCheckinTracker] = React.useState()

  React.useEffect(() => {
    const track = async () => {
      await globalContext.analytics?.trackEvent(events.START_FEEDBACK, {
        classId: lessonId,
        isExplore: window.location?.hash === '#explore',
        isFirstPostLesson: true,
      })
    }
    track()
  }, [globalContext.analytics, lessonId])

  React.useEffect(() => {
    async function getCheckin() {
      const afterClassCheckinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'after_class',
          code: 'movement_mastery_after_first_class',
          activeOnly: true,
          objectId: lessonId,
          createIfNotExists: true,
        })
        .catch((error: any) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: 'movement mastery post first class',
            type: 'after_class',
          })
          notifications.notifyError()
        })

      const afterClassCheckin = afterClassCheckinResponse?.data

      if (!afterClassCheckin) return

      setCheckinTracker(
        new CheckinTracker(globalContext.user?.id, afterClassCheckin, globalContext.analytics)
      )
    }
    getCheckin()
  }, [globalContext.user?.id, lessonId, globalContext.analytics])

  async function handleSuccess() {
    const afterClassResponse = await checkinApiRequest.getLatestUserCheckinResponses({
      checkinCode: 'movement_mastery_after_first_class',
    })

    if (afterClassResponse?.statusCode !== 200 || !afterClassResponse?.data) {
      notifications.notifyError()
      return
    }

    let lessonFeedbackDifficulty = ''
    if (afterClassResponse?.data?.more_challenging?.responseValue === 'yes') {
      lessonFeedbackDifficulty = programs.CLASS_TOO_EASY
    } else if (afterClassResponse?.data?.less_challenging?.responseValue === 'yes') {
      lessonFeedbackDifficulty = programs.CLASS_TOO_HARD
    }

    // if no program change, go back to program page
    if (_.isEmpty(lessonFeedbackDifficulty)) {
      navigate(paths.PROGRAM, { replace: true })
    } else {
      // otherwise, update program
      navigate(`${paths.PROGRAM_BUILD}?lessonFeedback=${lessonFeedbackDifficulty}`, {
        replace: true,
      })
    }
  }

  return (
    <div className="ProgramFirstPostLesson">
      <Head bodyAttributes={{ class: 'body---grey body---has-subheader' }} />
      <HeaderSubheader>
        <Row size="xsmall">
          <Text element="h1" weight="semibold">
            Share class feedback
          </Text>
        </Row>
        {!isProgramLoading && <ProgramFirstLessonLesson data={program?.firstLesson} />}
      </HeaderSubheader>
      <View>
        <Container flush size="small">
          <CheckinSingleForm
            align="left"
            buttonProps={{
              children: 'Submit feedback',
              color: 'yellow',
              full: true,
            }}
            checkinTracker={checkinTracker}
            size="medium"
            onSuccess={handleSuccess}
          />
        </Container>
      </View>
    </div>
  )
}
