import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ActivityView from 'app/components/ActivityView'
import SubscriptionUpgradeSection from 'app/components/SubscriptionUpgradeSection'
import AssessmentCards from 'assessments/components/AssessmentCards'
import Container from 'components/Container'
import Row from 'components/Row'
import Text from 'components/Text'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as subscriptions from 'libs/subscriptions'
import styles from './Assessments.module.scss'

//import { ReactComponent as HeroSvg } from './images/hero.svg'

export default function Assessments() {
  const globalContext = useGlobalContext()

  return (
    <ActivityView title="Assessments" theme="dark" className="Assessments">
      {/*<HeroSvg className={styles['hero-image']} />*/}
      <StaticImage
        src="./images/hero@2x.png"
        alt=""
        layout="fullWidth"
        options={{
          quality: 100,
        }}
        className={classNames(styles['hero-image'], styles['hero-image---large'])}
      />
      <StaticImage
        src="./images/hero---small@2x.png"
        alt=""
        layout="fullWidth"
        options={{
          quality: 100,
        }}
        className={classNames(styles['hero-image'], styles['hero-image---small'])}
      />
      <Row>
        <Container flush size="xlarge">
          <Text color="white" element="header">
            <Container align="left" flush size="medium">
              <Row size="small">
                <Text element="h1" size="xxxxlarge" weight="semibold">
                  Let’s check in.
                </Text>
              </Row>
              <Text element="p" flush size="xlarge">
                Assess your current fitness level, and track your progress over time.
              </Text>
            </Container>
          </Text>
        </Container>
      </Row>
      <Row size="xxlarge">
        <AssessmentCards />
      </Row>
      {globalContext.user?.subscription &&
        subscriptions.isFreePlan(globalContext.user?.subscription?.plan) && (
          <Text align="center" element="section">
            <Container flush>
              <SubscriptionUpgradeSection />
            </Container>
          </Text>
        )}
    </ActivityView>
  )
}
