import * as api from 'libs/api'

export async function listUsers(email) {
  try {
    return await api.get(`admin/users?email=${email}`)
  } catch (error) {
    return { error }
  }
}

export async function getUser(id: string) {
  try {
    return await api.get(`admin/user/${id}`)
  } catch (error) {
    return { error }
  }
}

export async function getUserGoal(id) {
  try {
    return await api.get(`admin/userGoal?id=${id}`)
  } catch (error) {
    return { error }
  }
}

export async function getUserExerciseProgram(id) {
  try {
    return await api.get(`admin/user/${id}/exerciseProgram`)
  } catch (error) {
    return { error }
  }
}

export async function updateUserGoal(id, properties) {
  try {
    return await api.post('admin/userGoal/update', {
      id,
      properties,
    })
  } catch (error) {
    return { error }
  }
}

export async function updateUser(id, data) {
  try {
    return await api.post(`admin/users/${id}`, data)
  } catch (error) {
    return { error }
  }
}

export async function pauseUser(id, startDate, endDate, reason, unpause) {
  try {
    return await api.post('admin/user/pause', {
      userId: id,
      startDate,
      endDate,
      reason,
      unpause,
    })
  } catch (error) {
    return { error }
  }
}

export async function deleteUser(id) {
  try {
    return await api.deleteApi(`admin/user/${id}`)
  } catch (error) {
    return { error }
  }
}

export async function updateUserProgram(data) {
  try {
    return await api.post('admin/updateUserProgram', data)
  } catch (error) {
    return { error }
  }
}

export async function createCollection(data) {
  try {
    return await api.post('admin/createCollection', data)
  } catch (error) {
    return { error }
  }
}

export async function createChallenge(data) {
  try {
    return await api.post('admin/createChallenge', data)
  } catch (error) {
    return { error }
  }
}

export async function getAllExercisePrograms(userId: string) {
  try {
    const result = await api.get(`admin/exercisePrograms/${userId}`)
    return result
  } catch (error) {
    return { error }
  }
}

export async function generateMonthlyUsageReportForEnterprise(enterpriseId, year, month) {
  try {
    return await api.post('admin/enterprise/activity', {
      enterpriseId,
      year,
      month,
    })
  } catch (error) {
    return { error }
  }
}

export async function getEnterpriseList() {
  try {
    return await api.get('enterprises')
  } catch (error) {
    return { error }
  }
}

export async function removeEnterpriseUser(email) {
  try {
    return await api.post('admin/removeEnterpriseUser', { email })
  } catch (error) {
    return { error }
  }
}

export async function migrateEnterpriseUser(email) {
  try {
    return await api.post('admin/migrateEnterpriseUser', { email })
  } catch (error) {
    return { error }
  }
}

export async function convertEnterpriseUser({ email, enterpriseInternalId, externalId }) {
  try {
    return await api.post('admin/convertEnterpriseUser', {
      email,
      enterpriseInternalId,
      externalId,
    })
  } catch (error) {
    return { error }
  }
}

// To link a user with an existing enterprise member from an eligibility file
export async function linkUserWithEnterpriseMember({ enterpriseMemberId, userId }) {
  try {
    return await api.post('admin/linkUserWithEnterpriseMember', {
      enterpriseMemberId,
      userId,
    })
  } catch (error) {
    return { error }
  }
}

export async function fetchBoldlessEnterpriseMembers(enterpriseId) {
  try {
    return await api.get(`admin/enterprise/boldless?enterpriseId=${enterpriseId}`)
  } catch (error) {
    return { error }
  }
}

export async function getLessonStreamsForDates(startDate, endDate) {
  try {
    return await api.get(`admin/lessonStreamsForDates?startDate=${startDate}&endDate=${endDate}`)
  } catch (error) {
    return { error }
  }
}

export async function getLessonRegimens() {
  try {
    return await api.get('admin/lessonRegimens/list')
  } catch (error) {
    return { error }
  }
}

export async function createOneoffLiveStream(
  lessonProperties,
  timestamp,
  lessonRegimenId = null,
  instructorEmail = null
) {
  try {
    return await api.post(`admin/lessonStream/oneOffLive/create`, {
      lessonProperties,
      timestamp,
      lessonRegimenId,
      instructorEmail,
    })
  } catch (error) {
    return { error }
  }
}

export async function createOneoffRerunStream(timestamp, lessonRegimenId = null) {
  try {
    return await api.post(`admin/lessonStream/oneOffRerun/create`, {
      timestamp,
      lessonRegimenId,
    })
  } catch (error) {
    return { error }
  }
}

export async function createLessonEntitiesForLessonRegimensForDateRange(startDate, endDate) {
  try {
    return await api.post(
      `admin/lessonRegimens/createEntities?startDate=${startDate}&endDate=${endDate}`
    )
  } catch (error) {
    return { error }
  }
}

export async function createLessonRegimen(data) {
  try {
    return await api.post('admin/lessonRegimen/new', data)
  } catch (error) {
    return { error }
  }
}

export async function updateLessonRegimen(id, data) {
  try {
    return await api.put(`admin/lessonRegimen/${id}/update`, data)
  } catch (error) {
    return { error }
  }
}

export async function getLessonStreamsForLessonRegimen(lessonRegimenId) {
  try {
    return await api.get(`admin/lessonRegimen/${lessonRegimenId}/streams`)
  } catch (error) {
    return { error }
  }
}

export async function toggleRerunEligibility(lessonStreamId, rerunEligible) {
  try {
    return await api.put(`admin/lessonStream/${lessonStreamId}/toggleEligibility`, {
      rerunEligible,
    })
  } catch (error) {
    return { error }
  }
}

export async function handleToggleExplorability(lessonId: string, onExplore: boolean) {
  try {
    return await api.put(`admin/lesson/${lessonId}`, {
      exploreStatus: onExplore ? 'queued' : null,
    })
  } catch (error) {
    return { error }
  }
}

export async function convertLiveLessonStreamToRerun(lessonStreamId) {
  try {
    return await api.put(`admin/lessonStream/${lessonStreamId}/convertToRerun`)
  } catch (error) {
    return { error }
  }
}

export async function cancelLessonStream(lessonStreamId) {
  try {
    return await api.put(`admin/lessonStream/${lessonStreamId}/cancel`)
  } catch (error) {
    return { error }
  }
}

export async function updateLessonUrlAndTitle(lessonId, videoLink, originalStreamTs) {
  try {
    const toUpdate = {
      videoLink,
      originalStreamTs,
    }
    return await api.put(`admin/lesson/${lessonId}`, toUpdate)
  } catch (error) {
    return { error }
  }
}

export async function getMarketingReport(reportType: string, endDate: string) {
  try {
    return await api.get(`admin/marketingReport?reportType=${reportType}&endDate=${endDate}`)
  } catch (error) {
    return { error }
  }
}

async function getLessons() {
  try {
    return await api.get('admin/lessons')
  } catch (error) {
    return { error }
  }
}

export async function getEnterpriseMember(id: string) {
  try {
    return await api.get(`admin/enterpriseMember/${id}/fetch`)
  } catch (error) {
    return { error }
  }
}

export async function updateEnterpriseMemberConfiguration(
  enterpriseMemberId: string,
  configuration: any
) {
  try {
    return await api.post(`admin/setEnterpriseMemberConfiguration`, {
      enterpriseMemberId,
      configuration,
    })
  } catch (error) {
    return { error }
  }
}

export default {
  generateMonthlyUsageReportForEnterprise,
  getEnterpriseList,
  getEnterpriseMember,
  getLessons,
}
