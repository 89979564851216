import React from 'react'
import { getMarketingReport } from 'admin/libs/admin-api-request'
import Button from 'components/Button'

function exportCSV(csvData: string, reportName: string, endDate: string) {
  const csvBlob = new Blob([csvData], { type: 'text/csv' })
  const csvBlobUrl = URL.createObjectURL(csvBlob)
  const downloadLink = document.createElement('a')
  downloadLink.href = csvBlobUrl
  downloadLink.download = `${reportName} - 2023-07-12 to ${endDate}.csv`
  downloadLink.click()
  URL.revokeObjectURL(csvBlobUrl)
}

export default function AdminMarketing() {
  const [selectedReport, setSelectedReport] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  const [downloading, setDownloading] = React.useState(false)

  const handleReportChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedReport(event.target.value)
    setErrorMessage('')
  }

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const endDateInput = event.target.value
    setEndDate(endDateInput)
    setErrorMessage('')
  }

  const validateDate = (date: string) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/
    if (!dateRegex.test(date)) {
      return false
    }

    const [year, month, day] = date.split('-').map(Number)

    if (month < 1 || month > 12) {
      return false
    }

    if (day < 1 || day > new Date(year, month, 0).getDate()) {
      return false
    }

    return true
  }

  const handleDownloadClick = async () => {
    if (!selectedReport) {
      setErrorMessage('Please select a report.')
      return
    }

    if (!validateDate(endDate)) {
      setErrorMessage('Please enter a valid date in yyyy-mm-dd format.')
      return
    }

    const today = new Date()
    const minDate = new Date('2023-07-12')
    const selectedEndDate = new Date(endDate)

    if (selectedEndDate <= minDate || selectedEndDate >= today) {
      setErrorMessage(
        `End date must be between "2023-07-12" and "${today.toISOString().split('T')[0]}"`
      )
      return
    }

    setDownloading(true)

    try {
      const reportType = selectedReport === 'Marketing Report - Phase 0 & 1' ? 'Basic' : 'SNP'
      const marketingReportResponse = await getMarketingReport(reportType, endDate)
      if (marketingReportResponse.statusCode === 200) {
        exportCSV(marketingReportResponse.data, selectedReport, endDate)
        setErrorMessage('')
      } else {
        throw new Error(marketingReportResponse.message)
      }
    } catch (error) {
      setErrorMessage(`Error downloading the report. ${error.message}`)
    } finally {
      setDownloading(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}>
      <div
        style={{
          border: '1px solid #ccc',
          padding: '20px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
        <h2
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
            fontSize: '24px',
          }}>
          Select Marketing Report to Download
        </h2>
        <select
          value={selectedReport}
          onChange={handleReportChange}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #ccc',
          }}>
          <option value="">Select a Report</option>
          <option value="Marketing Report - Phase 0 & 1">Marketing Report - Phase 0 & 1</option>
          <option value="Marketing Report - SNP">Marketing Report - SNP</option>
        </select>
        <input
          type="text"
          placeholder="Please enter the date until which you want the report (yyyy-mm-dd)"
          value={endDate}
          onChange={handleEndDateChange}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid #ccc',
          }}
        />
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <Button onClick={handleDownloadClick} disabled={downloading}>
          {downloading ? 'Downloading…' : 'Download'}
        </Button>
      </div>
    </div>
  )
}
