import { navigate } from 'gatsby'
import React from 'react'
import FormFieldsRequired from 'components/FormFieldsRequired'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import EnterpriseEligibilityForm from 'enterprise/components/EnterpriseEligibilityForm'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as cookie from 'libs/cookie'
import enterprise from 'libs/enterprise'
import * as user from 'libs/user'
import OnboardingLayout from 'onboarding/components/OnboardingLayout'
import OnboardingView from 'onboarding/components/OnboardingView'

export default function OnboardingEnterpriseEligibilityRecheck() {
  useEventPageVisited('Onboarding enterprise eligibility recheck')
  const globalContext = useGlobalContext()
  const isSpecial = user.hasHomeSpecial()
  const partner = cookie.getCookie(storage.HOME_KEY) || globalContext.partner

  function handleSuccess(updatedUser: any) {
    if (isSpecial && !user.isEnterpriseUser(updatedUser)) {
      navigate(paths.ONBOARDING_INTRO) // skip nay elig screen for special rn
    } else {
      navigate(paths.ONBOARDING_ENTERPRISE_ELIGIBILITY)
    }
  }

  React.useEffect(() => {
    // skip this page if already enterprise
    if (globalContext?.user?.enterpriseMemberId) handleSuccess()

    // skip if already completed OB checkin
    async function getCheckin() {
      const checkinResponse = await checkinApiRequest.getUserCheckin({
        activeOnly: false,
        createIfNotExists: false,
        type: 'onboarding',
      })

      if (checkinResponse?.statusCode === 200 && checkinResponse?.data?.status === 'complete') {
        navigate(paths.ONBOARDING_PROFILE, { replace: true })
      }
    }
    getCheckin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const track = async () => {
      if (enterprise.PARTNERS_WITH_ELIGIBILITY_FILE.includes(partner)) {
        await globalContext.analytics?.trackEvent(
          events.ENTERPRISE_MEMBER_NOT_MATCHED_ON_NAME_DOB,
          {
            enterprise: partner,
          }
        )
      }
    }
    track()
  }, [globalContext.analytics, partner])

  async function handleSubmit(values: any) {
    await globalContext.analytics?.trackEvent(
      'Onboarding enterprise eligibility recheck: Recheck my eligibility button click',
      {
        enteredZip: values.postalCode ? 'true' : 'false',
        enteredPhone: values.phoneNumber ? 'true' : 'false',
        enteredMemberId: values.memberID ? 'true' : 'false',
        enteredReefOrcaCode: values.externalId ? 'true' : 'false',
        isSpecial,
      }
    )
  }

  async function handleSkip() {
    await globalContext.analytics?.trackEvent(
      'Onboarding enterprise eligibility recheck: Skip button click',
      { isSpecial }
    )
  }

  return (
    <OnboardingLayout title="Eligibility" className="OnboardingEnterpriseEligibilityRecheck">
      <OnboardingView title="Finish checking your eligibility">
        <Text element="p">
          We haven’t been able to verify you have free access to Bold through your health plan yet —
          sorry about that. Check if you’re eligible by entering some more information.
        </Text>
        {!isSpecial && <FormFieldsRequired />}
        <EnterpriseEligibilityForm
          from="recheck"
          onSkip={handleSkip}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
        />
      </OnboardingView>
    </OnboardingLayout>
  )
}
