import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Loader from 'components/Loader'
import Playlist from 'components/Playlist'
import type { Playlist as PlaylistType } from 'constants/types'
import * as challengeApiRequest from 'libs/challenge-api-request'
import * as lessonApiRequest from 'libs/lesson-api-request'
import styles from './Playlists.module.scss'

export default function Playlists() {
  const [playlists, setPlaylists] = React.useState<PlaylistType[]>([])

  React.useEffect(() => {
    async function getPlaylists() {
      const response = await lessonApiRequest.getPlaylists().catch((error: Error) => {
        console.error(error)
      })

      const { data } = response

      if (_.isEmpty(data)) return

      let allPlaylists = data

      // don't include collections associated with past or future challenges
      allPlaylists = _.filter(data, (item) => !item?.title?.includes('Challenge'))

      // include collections associated with any active challenges though
      const challengeResponse = await challengeApiRequest
        .getActiveChallenges({ type: 'oneoff' })
        .catch((error: Error) => {
          console.error(error)
        })

      if (!_.isEmpty(challengeResponse?.data)) {
        const collectionId = challengeResponse.data[0].collectionId
        const playlistResponse = await lessonApiRequest
          .getPlaylist(collectionId)
          .catch((error: any) => {
            console.error(error)
          })

        if (!_.isEmpty(playlistResponse?.data)) {
          allPlaylists = allPlaylists.concat(playlistResponse.data.playlist)
        }
      }

      setPlaylists(_.orderBy(allPlaylists, 'created_at', 'desc'))
    }
    getPlaylists()
  }, [])

  return (
    <div className={classNames('Playlists')}>
      {_.isEmpty(playlists) ? (
        <Loader overlay={false} />
      ) : (
        <div className={styles.grid}>
          {playlists.map((item) => (
            <Playlist data={item} key={item.id} />
          ))}
        </div>
      )}
    </div>
  )
}
