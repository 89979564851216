import classNames from 'classnames'
import React from 'react'
import Fieldset from 'components/Fieldset'
import Image from 'components/Image'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Tag from 'components/Tag'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import enterprise from 'libs/enterprise'
import user from 'libs/user'
import styles from './EnterpriseSubscription.module.scss'

type Props = Component

export default function EnterpriseSubscription({ className, ...props }: Props) {
  const globalContext = useGlobalContext()

  if (!globalContext.isEnterprise || !globalContext.user.enterpriseMember?.validated) return null

  return (
    <Fieldset {...props} className={classNames('EnterpriseSubscription', className)}>
      <MediaObject
        center
        figure={
          !user.isEnterpriseTrialUser(globalContext.user) ? (
            <Image
              src={user.getEnterprisePartnerLogo(globalContext.user)}
              alt={user.getEnterprisePartnerName(globalContext.user)}
              className={styles.logo}
            />
          ) : null
        }
        figurePosition="right"
        reset="mobile">
        <Row size="xxsmall">
          <MediaObject
            figure={
              <Tag casing="capitalize" color="purple100" weight="medium">
                Bold Health
              </Tag>
            }
            center
            figurePosition="right"
            inline
            size="xxsmall">
            <Text size="large" weight="semibold">
              Bold Premium
            </Text>
          </MediaObject>
        </Row>
        <Text element="p" flush>
          You currently have membership access at no cost{' '}
          {user.isEnterpriseTrialUser(globalContext.user)
            ? 'for one year'
            : `through ${enterprise.getNameTechnical(
                globalContext.user.enterpriseMember?.enterprise?.internalId,
                globalContext.user
              )}`}
          .
        </Text>
      </MediaObject>
    </Fieldset>
  )
}
