import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import { useIntersection } from 'react-use'
import AppDownloadModal from 'app/components/AppDownloadModal'
import LessonVideo from 'app/components/LessonVideo'
import ProgramFirstLessonMovements from 'app/components/ProgramFirstLessonMovements'
import Box from 'components/Box'
import Button from 'components/Button'
import ButtonsFloating from 'components/ButtonsFloating'
import Container from 'components/Container'
import Head from 'components/Head'
import Heading2 from 'components/Heading2'
import LessonTrainer from 'components/LessonTrainer'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import { useLatestUserCheckinResponses } from 'libs/checkin-query'
import { useMovementsForLesson } from 'libs/lesson-query'
import navigation from 'libs/navigation'
import { useProgram } from 'libs/program-query'
import styles from './ProgramFirstLesson.module.scss'

function getReasonCopy(motivation: string) {
  // prettier-ignore
  return {
    'balance': 'for you because it focuses on movements that improve your balance',
    'joint pain': 'for you because it focuses on gentle movements designed to help you manage and reduce pain',
    'new exercises': 'to add a fresh element to your routine',
    'habit': 'to jumpstart your new routine',
  }[motivation.split(',')[0]]
}

export default function ProgramFirstLesson() {
  useEventPageVisited('Program first lesson')
  const globalContext = useGlobalContext()
  const { data: latestUserCheckinResponsesData } = useLatestUserCheckinResponses()
  const latestUserCheckinResponses = latestUserCheckinResponsesData?.data
  const { data: programData, isLoading: isProgramLoading } = useProgram({
    revalidateOnFocus: false,
  })
  const program = programData?.data
  const lessonId = program?.firstLessonId
  const { data: movementsForLessonData, isLoading: isMovementsForLessonLoading } =
    useMovementsForLesson(lessonId, {
      dependencies: [programData],
      revalidateOnFocus: false,
    })
  const movements = movementsForLessonData?.data
  const videoIntersectionRef = React.useRef(null)
  const intersection = useIntersection(videoIntersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  })

  function handleLessonEnd() {
    navigate(paths.PROGRAM_FIRST_POST_LESSON)
  }

  function handleStartClassClick() {
    navigation.scrollToTop()
  }

  const motivation = latestUserCheckinResponses?.motivation_to_start_bold?.responseValue

  return (
    <View color="grey" className="ProgramFirstLesson">
      <Head
        bodyAttributes={{
          class: 'body---hide-nav body---hide-auth body---hide-nav-bottom body---hide-footer',
        }}
      />
      <AppDownloadModal />
      <Container flush size="small">
        <Heading2 level={1} levelStyle={4}>
          Hey <Text color="purple">{globalContext.user.firstName}</Text>! Here’s your first Bold
          class
        </Heading2>
        {!!motivation && <Text element="p">We chose this class {getReasonCopy(motivation)}.</Text>}
        {isProgramLoading ? (
          <Loader overlay={false} />
        ) : (
          <Row size="smallx">
            <Row size="smallx">
              <LessonVideo
                lesson={program.firstLesson}
                rounded
                className={styles.video}
                onEnd={handleLessonEnd}
              />
              <div ref={videoIntersectionRef} />
            </Row>
            <Heading2 level={2} levelStyle={5}>
              {program.firstLesson.videoTitle}
            </Heading2>
            <LessonTrainer data={program.firstLesson} />
          </Row>
        )}
        <Row size="smallx">
          {isMovementsForLessonLoading ? (
            <Loader overlay={false} />
          ) : (
            <Box color="white" size="small">
              <Heading2 level={2} levelStyle={5}>
                Preview this class
              </Heading2>
              <Text element="p">
                Here are some of the movements you will see in this class. Learn how to perform each
                movement safely before you start this class.
              </Text>
              <ProgramFirstLessonMovements data={movements} lesson={program.firstLesson} />
            </Box>
          )}
        </Row>
        <ButtonsFloating show={(intersection && !intersection.isIntersecting) ?? false}>
          <Button color="yellow" full onClick={handleStartClassClick}>
            Start class
          </Button>
        </ButtonsFloating>
      </Container>
    </View>
  )
}
