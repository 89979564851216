import classNames from 'classnames'
import _ from 'lodash'
import { DateTime } from 'luxon'
import pluralize from 'pluralize'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import ButtonClose from 'components/ButtonClose'
import Link from 'components/Link'
import Progress from 'components/Progress'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import storage from 'constants/storage'
import type { Challenge as ChallengeType, Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as challengeApiRequest from 'libs/challenge-api-request'
import * as cookie from 'libs/cookie'
import notifications from 'libs/notifications'
import user from 'libs/user'
import styles from './Challenge.module.scss'

type Props = Component

export default function Challenge({ className }: Props) {
  const globalContext = useGlobalContext()
  const challengedDismissedFlag = cookie.getCookie(storage.DASHBOARD_CHALLENGE_KEY)
  const dismissedFlagDate = Date.parse(challengedDismissedFlag)
  const [challenge, setChallenge] = React.useState<ChallengeType | {}>({})
  const [challengeActive, setChallengeActive] = React.useState<ChallengeType | {}>({})
  const [challenges, setChallenges] = React.useState<ChallengeType[]>([])
  const [isChallengeActive, setIsChallengeActive] = React.useState(false)
  const [isJoiningChallenge, setIsJoiningChallenge] = React.useState(false)
  const [show, setShow] = React.useState(
    Number.isNaN(dismissedFlagDate)
      ? true
      : dismissedFlagDate < DateTime.now().minus({ weeks: 2 }).toMillis()
  )

  const getData = React.useCallback(async () => {
    if (user.isEnterpriseOctopusUser(globalContext.user)) return

    const challengeType = 'oneoff'

    const challengesResponse = await challengeApiRequest.getActiveChallenges({
      type: challengeType,
    })

    const challengesActiveResponse = await challengeApiRequest.getUserChallenges({
      status: 'active',
      type: challengeType,
    })

    const challengesData = challengesResponse?.data
    const challengeData = _.orderBy(challengesData, 'activatedAt', 'asc')[0]
    const challengeActiveData = _.orderBy(challengesActiveResponse?.data, 'createdAt', 'asc')[0]

    setChallenges(challengesData)
    setChallenge(challengeData)
    setChallengeActive(challengeActiveData)
    setIsChallengeActive(challengeData?.id === challengeActiveData?.challengeId)
  }, [globalContext.user])

  React.useEffect(() => {
    getData()
  }, [getData])

  function handleClose() {
    setShow(false)
    cookie.createCookie(storage.DASHBOARD_CHALLENGE_KEY, DateTime.now().toISO())
  }

  // TODO: dedupe with app/pages/Activity/components/Challenges
  async function handleJoinChallenge(challengeId, challengeName) {
    try {
      // Prevent double click
      // https://stackoverflow.com/questions/35315872/reactjs-prevent-multiple-times-button-press
      if (isJoiningChallenge) {
        return null
      }

      setIsJoiningChallenge(true)
      await challengeApiRequest.joinChallenge(challengeId)

      //setIsChallengeActive(true)
      getData()
      setIsJoiningChallenge(false)
      notifications.notifySuccess('Thanks for joining the challenge!')
      globalContext.analytics?.trackEvent(events.DASHBOARD_CHALLENGE_JOIN_BUTTON_CLICK, {
        challengeId,
        challengeName,
        success: true,
      })
      globalContext.analytics?.trackEvent(events.JOIN_CHALLENGE, {
        challengeId,
        challengeName,
        success: true,
      })
    } catch {
      setIsJoiningChallenge(false)
      notifications.notifyError(
        'Something went wrong joining that challenge. Please try again later.'
      )
      globalContext.analytics?.trackEvent(events.JOIN_CHALLENGE, {
        challengeId,
        challengeName,
        success: false,
      })
    }
  }

  if (user.isEnterpriseOctopusUser(globalContext.user) || _.isEmpty(challenge) || !show) return null

  const hasChallenges = _.size(challenges) > 1

  return (
    <>
      <hr className={styles.divider} />
      <Row size="xsmall" className={classNames('Challenge', className)}>
        <Box color="white">
          {!isChallengeActive && (
            <ButtonClose className={styles['close-button']} onClick={handleClose} />
          )}
          <Row size="xxxsmall">
            <Text element="h3" weight="semibold">
              {challenge.name}
            </Text>
          </Row>
          <Text element="p" lines={2}>
            {challenge.description}
          </Text>
          {!isChallengeActive && (
            <Button
              color="yellow"
              loading={isJoiningChallenge}
              onClick={() => handleJoinChallenge(challenge.id, challenge.name)}>
              Join challenge
            </Button>
          )}
          {isChallengeActive && (
            <>
              <Row size="small">
                <Button to={paths.getChallengePath(challenge)} level="text">
                  View classes →
                </Button>
              </Row>
              <Row size="xxsmall">
                <Text weight="medium">
                  {Math.round(challengeActive.progress)} of{' '}
                  {pluralize(
                    challengeActive.challenge.unit,
                    challengeActive.challenge.threshold,
                    true
                  )}
                </Text>
              </Row>
              <Progress
                value={challengeActive.progress}
                total={challengeActive.challenge.threshold}
                color="green"
                size="small"
              />
            </>
          )}
        </Box>
      </Row>
      {hasChallenges && (
        <Link to={paths.ACTIVITY_CHALLENGES}>
          <Text weight="medium">View all challenges</Text>
        </Link>
      )}
    </>
  )
}
