import _ from 'lodash'
import React from 'react'
import Image from 'components/Image'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Lesson, LessonMovement } from 'constants/types'
import navigation from 'libs/navigation'
import styles from './ProgramFirstLessonMovements.module.scss'
import ProgramFirstLessonMovementModal from './components/ProgramFirstLessonMovementModal'
import { ReactComponent as ArrowRightSvg } from './images/arrow---right.svg'

type Props = {
  data: LessonMovement[]
  lesson?: Lesson
}

export default function ProgramFirstLessonMovements({ data, lesson }: Props) {
  const [movement, setMovement] = React.useState<LessonMovement | null>()

  function handleMovementClick(movementSelected: LessonMovement) {
    setMovement(movementSelected)
  }

  function handleRequestClose() {
    setMovement(null)
    navigation.scrollToTop()
  }

  if (_.isEmpty(data)) return null

  return (
    <div className="ProgramFirstLessonMovements">
      <ProgramFirstLessonMovementModal
        data={movement as LessonMovement}
        lesson={lesson as Lesson}
        isOpen={!_.isEmpty(movement)}
        onRequestClose={handleRequestClose}
      />
      {data.map((item: LessonMovement) => (
        <Row key={item.movementId} size="xxsmall">
          <button
            type="button"
            className={styles.movement}
            onClick={() => handleMovementClick(item)}>
            <figure>
              <Image src={item.videoThumbnail} className={styles['movement--image']} />
              <Text
                color="black"
                element="figcaption"
                weight="medium"
                className={styles['movement--title']}>
                <MediaObject figure={<ArrowRightSvg aria-hidden />} center figurePosition="right">
                  {item.movementName}
                </MediaObject>
              </Text>
            </figure>
          </button>
        </Row>
      ))}
    </div>
  )
}
